import axios from 'axios'
import React, { useEffect, useState } from 'react'

function MTWishes(props) {
    const [wishList, setWishList] = useState([])
    const getWishes = async () => {
        try {
            const {data} = await axios.get(window.server + '/api/wishes/listwishtomt/')
            const tempArr = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const fromUser = await getUser(element.fromid)
                if (fromUser && fromUser.name) {
                    element.fromName = fromUser.name
                    element.fromAvatar = fromUser.avatar
                    tempArr.push(element)
                }
            }
            tempArr.reverse()
            setWishList(tempArr)
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async (id) => {
        try {
            const res = await axios.get(window.server + '/api/users/' + id)
            return res.data[0]
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getWishes()
        return () => {
        }
    }, [])
    
    return (
        <div className='all-wishes'>
            <div className='nav-bar'>
                <span
                    className='tab'
                >MT收到的祝福</span>
                <button
                    className='btn btn-yellow btn-small'
                    onClick={() => {
                        setWishList([])
                        getWishes()
                    }}
                >刷新</button>
                <button
                    className='btn btn-yellow btn-small'
                    onClick={() => {
                        props.close()
                    }}
                >继续送祝福</button>
            </div>
            <div className='wishes-list'>
                {wishList.length > 0 && wishList.map((wish, key) => {
                    return <div className='wish-item' key={key}>
                        <div className='wish-title'>
                            <span>来自：{wish.fromName}</span>
                        </div>
                        <div className='wish-content'>
                            {wish.content}
                        </div>
                    </div>
                })}
                {!wishList.length && <div className='loader'></div>}
            </div>
        </div>
    )
}

export default MTWishes