import React, { useState } from "react"
import Login from "./pages/Login"
import { CookiesProvider } from 'react-cookie'
import Routers from "./Routers"
import './App.css'
import { useLocation } from "react-router"

window.server = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://itostartup.com:3001'
console.log('env:', process.env.NODE_ENV)
// 微信服务号网页授权
window.appid = 'wx7b2a671c0dd87457'
window.appsecret = '618ec84265629630d3a439da493389cf'
window.redirect_uri = 'https%3A%2F%2Fmt2023.actionone.cn'

function App() {
  const location = useLocation()
  const [adminCmd, setAdminCmd] = useState()
  return (
    <>
      <CookiesProvider>
        {location.pathname !== '/' && location.pathname !== '/admin/control' && location.pathname !== '/admin/control' && <Login onCmd = {setAdminCmd} />}
        <Routers adminCmd = {adminCmd} />
      </CookiesProvider>
    </>
  )
}

export default App;
