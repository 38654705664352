import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import ChooseTarget from './components/ChooseTarget'
import AllWishes from './components/AllWishes'
import './Wishes.css'

function WishesHome() {
    const [cookies, setCookie] = useCookies()
    const [user, setUser] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [flyLeft, setFlyLeft] = useState(false)
    const [step, setStep] = useState()
    const [sent, setSent] = useState(false)
    const closePopup = (feedback) => {
        if (feedback === 'sent') {
            setSent(true)
        }
        updateUser(cookies.user.id)
        setFlyLeft(true)
        setTimeout(() => {
            setShowPopup(false)
            setFlyLeft(false)
        }, 250)
    }
    const updateUser = (id) => {
        axios.get(window.server + "/api/users/" + id)
        .then((res) => {
            console.log(res.data[0])
            setUser(res.data[0])
            setCookie('user', res.data[0], {secure: true, sameSite: 'none'})
        }).catch((err) => {
            console.log(err)
        })

    }
    useEffect(() => {
        if (cookies.user) {
            updateUser(cookies.user.id)
        }

        return () => {
        
        }
    }, [])
    
    return (
        <div className='page wishes-home'>
            {sent ?
                <div className='sent'>
                    <p>您的祝福已送出</p>
                </div>
                :
                user && user.wishes_done !==1 && user.wishes_received !== 1 ? 
                    <div className='guide'>
                        <p>岁月不居，时节如流。<br />倏忽间，我们又一起走过了一年。<br />2023年，将是MT的十年。<br />感谢所有MTer的付出与陪伴，<br />感谢你们飞扬的青春和无悔的奉献！<br />新的一年，<br />MT只愿与你奔赴漫漫山海，并肩那繁星大海，<br />继续奔走在热爱里，谱写属于我们的篇章！</p>
                        <p>亲爱的MTer，也动动手指，<br /><span className='text-yellow'>送上你对身边同学的温暖祝福吧！</span></p>
                    </div>
                    :
                    <div className='guide'></div>
                
            }
            <div className='buttons'>
                {user && user.wishes_done === 1 && 
                    <button
                        className='btn btn-yellow'
                        onClick={()=>{
                            setStep('wishesSent')
                            setShowPopup(true)
                        }}
                    >我送出的祝福</button>
                }
                {user && user.wishes_received === 1 && 
                    <button
                        className='btn btn-yellow'
                        onClick={()=>{
                            setStep('wishesReceived')
                            setShowPopup(true)
                        }}
                    >我收到的祝福</button>
                }
                <button
                    className='btn btn-yellow'
                    onClick={()=>{
                        setStep('chooseTarget')
                        setShowPopup(true)
                    }}
                >{user && user.wishes_done ? '继续送祝福' : '开始送祝福'}</button>
            </div>
            {/* {showPopup &&  */}
                <div className={flyLeft ? 'page fly-left' : showPopup ? 'page pop-up' : 'd-none'}>
                    {showPopup && step === 'chooseTarget' &&
                        <ChooseTarget close={closePopup} />
                    }
                    {showPopup && step === 'wishesSent' &&
                        <AllWishes close={closePopup} active='sent'/>
                    }
                    {showPopup && step === 'wishesReceived' &&
                        <AllWishes close={closePopup} active='received' />
                    }
                </div>
            {/* } */}
        </div>
    )
}

export default WishesHome