import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <span className={isDanger ? 'countdown danger' : 'countdown'}>
      {('0' + value).slice(-2)}
    </span>
  );
};

export default DateTimeDisplay;
