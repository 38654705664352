import React, { useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import './ChargeMT.css'
import spiritEmpty from '../../imgs/spirt-empty.png'
import spiritFull from '../../imgs/spirit-full.png'
import CountdownTimer from '../step2/components/CountdownTimer'
import axios from 'axios'
import { useCookies } from 'react-cookie'

let socket
const THIRTY_SECONDS_IN_MS = 30 * 1000
let countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
let clickTime = null

function ChargeMT(props) {
    const [connection, setConnection] = useState(false)
    const [isStarted, setIsStarted] = useState(false)
    const [showTutorial, setShowTutorial] = useState(0)
    const [countDown, setCountDown] = useState(0)
    const [bar, setBar] = useState(0)
    const stick = useRef(null)
    const [result, setResult] = useState(0)
    const [showResult, setShowResult] = useState(false)
    const [showRedPacket, setShowRedPacket] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies()

    const connect = () => {
        socket.emit("checkin_connect", 'bigscreen')
    };
    const getCurrentState = () => {
        axios.get(window.server + '/api/getstage')
        .then((res) => {
            console.log(res);
            if (res.data.name === 'charge') {
                switch (res.data.started) {
                    case 0:
                        setIsStarted(false)
                        setResult(0)
                        setShowTutorial(1)
                        setShowResult(false)
                        setBar(0)
                        removeCookie('charge_started')
                        removeCookie('bar')
                        break;
                    case 1:
                        setShowResult(false)
                        setResult(1)
                        if(cookies.bar) {
                            setBar(Number(cookies.bar))
                        } else {
                            setBar(0)
                        }
                        setShowTutorial(0)
                        setIsStarted(true)
                        if(Number(cookies.bar) < 100) {
                            if(cookies.charge_started) {
                                countDownForThirtySeconds = Number(cookies.charge_started)
                            } else {
                                countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                                setCookie('charge_started', countDownForThirtySeconds, {secure: true, sameSite: 'none'})
                            }
                        } else {
                            countDownForThirtySeconds = new Date().getTime()
                        }
                        break;
                    case 2:
                        setShowResult(false)
                        setResult(2)
                        if(cookies.bar) {
                            setBar(Number(cookies.bar))
                        } else {
                            setBar(0)
                        }
                        setShowTutorial(0)
                        setIsStarted(true)
                        if(Number(cookies.bar) < 100) {
                            if(cookies.charge_started) {
                                countDownForThirtySeconds = Number(cookies.charge_started)
                            } else {
                                countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                                setCookie('charge_started', countDownForThirtySeconds, {secure: true, sameSite: 'none'})
                            }
                        } else {
                            countDownForThirtySeconds = new Date().getTime()
                        }
                        break;
                    case 100:
                        setResult(2)
                        setIsStarted(false)
                        setShowResult(true)
                        setShowResult(false)
                        setResult(2)
                        removeCookie('charge_started')
                        removeCookie('bar')
                        setShowRedPacket(true)
                        break;
                    case 42:
                        setShowResult(false)
                        setResult(1)
                        if(cookies.bar) {
                            setBar( Number(cookies.bar))
                        } else {
                            setBar(0)
                        }
                        setShowTutorial(0)
                        setIsStarted(false)
                        break;
                
                    default:
                        break;
                }
            } else {
                setIsStarted(false)
                setShowTutorial(1)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const chargeIt = () => {
        if(isStarted) {
            if (bar < 100) {
                const temp = result === 1 ? bar + 1.5 : bar + 1.5
                setBar(temp)
                setCookie('bar', temp, {secure: true, sameSite: 'none'})
                const newClickTime = new Date().getTime()
                if (!clickTime || newClickTime - clickTime > 1000) {
                    const room = 'bigscreen'
                    socket.emit("send_charge", {room})
                    clickTime = newClickTime
                }
            } else {
                setBar(100)
                setIsStarted(false)
            }
        }
    }
    useEffect(() => {
        stick.current.style.height = bar + '%'
        if(bar >= 100) {
            setIsStarted(false)
        }
        return () => {
        }
    }, [bar])

    const sendRedPacket = (type) => {
        axios.post(window.server + '/api/sendredpacket', {
            openid: cookies.user.openid,
            redpacket: type
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (showRedPacket) {
            // sendRedPacket('charging')
        }

        return () => {
        }
    }, [showRedPacket])
    
    useEffect(() => {
        console.log(props);
        if (props.adminCmd) {
            switch (props.adminCmd.command) {
                case 'start_charging':
                    setShowTutorial(4)
                    setShowResult(false)
                    setShowRedPacket(false)
                    setResult(props.adminCmd.value)
                    setBar(0)
                    setTimeout(() => {
                        setShowTutorial(0)
                        setIsStarted(true)
                        countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                        setCookie('charge_started', countDownForThirtySeconds, {secure: true, sameSite: 'none'})
                    }, 1000);
                    break;
                case 'clear_charging':
                    setIsStarted(false)
                    setResult(0)
                    setShowTutorial(1)
                    setShowResult(false)
                    setShowRedPacket(false)
                    setBar(0)
                    removeCookie('charge_started')
                    removeCookie('bar')
                    break;
                case 'full_charging':
                    setIsStarted(false)
                    // setShowResult(true)
                    setShowResult(false)
                    setResult(2)
                    removeCookie('charge_started')
                    // removeCookie('bar')
                    setShowRedPacket(true)
                    break;
                case 'end_charging':
                    setIsStarted(false)
                    // setShowResult(true)
                    setShowResult(false)
                    removeCookie('charge_started')
                    // removeCookie('bar')
                    // if (result === 2) {
                    //     setTimeout(() => {
                    //         setShowRedPacket(true)
                    //     }, 2000);
                    // }
                    break;
            
                default:
                    break;
            }
        }
        return () => {
        }
    }, [props])

    useEffect(() => {
        socket = io(window.server)
        connect()
        socket.on('connect', ()=> {
            console.log('connected')
            setConnection(true)
        })
        socket.on('disconnect', (reason)=> {
            console.log('disconnected', reason)
            setConnection(false)
            socket.connect()
        })
        getCurrentState()
        return () => {
            socket.removeAllListeners()
        }
    }, [])
    return ( 
        <div className='page charge'>
            <div className='page spirit-charging'>
                {showTutorial === 2 &&
                    <span
                        className='tutorial-text step-2'
                        onClick={() => {
                            if (showTutorial === 2) setShowTutorial(3)
                        }}
                    >MTer小伙伴的每次点击，都将转化为MT能量，逐渐充满MT。</span>
                }
                <div className={'spirit-bar-frame'}>
                    <div className='spirit-bar' ref={stick}></div>
                </div>
                {bar >= 100 ?
                <img src={spiritFull} alt='full' className='spirit-full'></img>
                :
                <img src={spiritEmpty} alt='empty' className='spirit-full'></img>
                }
                <div
                    className={showTutorial === 1 ? 'charge-now tutorial-active' : 'charge-now'}
                    onClick={() => {
                        if (showTutorial === 1) setShowTutorial(2)
                    }}
                >
                    {(isStarted && bar < 100) &&
                        <>
                            <button className='btn-charge' onClick={chargeIt}></button>
                            <span>蓄能</span>
                        </>
                    }
                    {(!isStarted && showTutorial > 0) &&
                        <>
                            <button className='btn-charge'></button>
                            <span>蓄能</span>
                        </>
                    }
                    {showTutorial === 1 &&
                        <span className='tutorial-text step-1'>活动开始后，限时30秒内，请快速点击此按键，为MT蓄能。</span>
                    }
                </div>
                {showTutorial === 0 && !isStarted && bar < 100 &&
                    <span className='charge-result'>时间到了，好遗憾。<br />虽然你的蓄能没有完成，但是也不要灰心。<br />如果其他同学积攒的能量够多，还是有机会成功的。<br />其他同学的能量正在上传中，我们一起稍作等待。</span>
                }
                {showTutorial === 0 && !isStarted && bar >= 100 &&
                    <span className='charge-result'><span className='em'>恭喜你</span><br />已经成功为MT献上自己的能量。<br />其他同学的能量也在上传中，<br />我们一起稍作等待。</span>
                }
                {showTutorial === 3 &&
                    <span
                        className='tutorial-text step-3'
                    >你的能量贡献值将上传至大屏幕汇总，并将影响MTer小伙伴在30秒内能否完成蓄能的整体目标。30秒内蓄能完成，则发放“齐心协力红包”。<br /><br />为了”齐心协力红包“，手不能停！赶快“疾跑+闪现”，一起点亮MT吧！</span>
                }
                {showTutorial === 4 &&
                    <span
                        className='tutorial-text step-4'
                    >开始</span>
                }
                {isStarted &&
                    <div className='count-down'>
                        <span className='clock'></span>
                        <span>倒计时：<CountdownTimer
                            targetDate={countDownForThirtySeconds}
                            onEnd = {() => {
                                setIsStarted(false)
                                // setShowResult(true)
                                // if (result === 2) {
                                //     setTimeout(() => {
                                //         setShowRedPacket(true)
                                //     }, 2000);
                                // }
                            }}
                        /></span>
                    </div>
                }
                {showTutorial !== 0 &&
                    <div
                        className='tutorial-bg'
                        onClick={() => {
                            if (showTutorial < 3)
                            setShowTutorial(showTutorial+1)
                        }}
                    ></div>
                }
                {showResult &&
                    <div className='spirit-charge-result'>
                        <span className={ result === 1 ? 'fail-face' : 'success-face'}></span>
                        {result === 1 ?
                        <div className='result-content'>
                            <p className='result-title'>非常遗憾<br />本次蓄能失败了</p>
                            <p>各路英雄豪杰们，是时候展现你们真正的实力了</p>
                            <p>请拿出六指琴魔般的手速<br />再来一次！</p>
                        </div>
                        :
                        <div className='result-content'>
                            <p className='result-title'>在大家的齐心协力下<br />蓄能成功！</p>
                            <p>红包在以三千米/秒的速度，<br />奔赴你的微信中...</p>
                        </div>
                        }
                    </div>
                }
                {showRedPacket &&
                    <div className='page red-packet'>
                        <div className='red-packet-content'>
                            <p className='content'>MT感谢你的付出和努力<br />你的声音能被听到<br />你的努力会被看到<br /><br />在MT，奖励从不缺席<br />新的一年，我们一起加油！</p>
                            <div className='title'><span>一起创造<br />MT SPIRIT</span></div>
                            <p className='text-yellow notes'>请在微信中查收</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ChargeMT