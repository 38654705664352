import React from 'react'
import io from 'socket.io-client'
import { useEffect, useState } from 'react'
import axios from 'axios'
import "./UsersManage.css"
import defaultavatar from '../../../imgs/defaul_avatar.png'

let socket

function UsersManage() {
    const [users, setUsers] = useState([])
    const [editing, setEditing] = useState(false)
    const [editKey, setEditKey] = useState(null)
    const [editName, setEditName] = useState('')
    const [editDepartment, setEditDepartment] = useState('')
    const [editEmail, setEditEmail] = useState('')
    const [submitting, setSubmitting] = useState(false)
  
    const getUsers = () => {
      console.log('getting users');
      axios.get(window.server + '/api/users')
      .then((res) => {
          console.log(res)
          setUsers(res.data)
      }).catch((err) => {
          console.log(err)
      })
    }
    
    // 预热阶段功能
    const updateInfo = (userid) => {
      console.log(userid, editName, editDepartment, editEmail)
      setSubmitting(true)
      axios.post(window.server + '/api/users/update', {
          id: userid,
          name: editName,
          department: editDepartment,
          email: editEmail
      }).then((res) => {
          console.log(res)
          if(res.data.changedRows) {
              setSubmitting(false)
              setEditing(false)
              setEditDepartment('')
              setEditEmail('')
              setEditName('')
              getUsers()
          }
      }).catch((err) => {
          console.log(err)
      })
  }
  const unBind = (userid) => {
      console.log(userid);
      axios.post(window.server + '/api/users/unbind', {
          id: userid
      }).then((res) => {
          console.log(res)
          if(res.data.changedRows) {
              getUsers()
              const room = 'bigscreen'
              const command = 'unbind'
              const value = userid
              socket.emit('admin_connect', {room, command, value})
          }
      }).catch((err) => {
          console.log(err)
      })
  }

    useEffect(()=>{
      getUsers()
      let refresher = setInterval(() => {
        getUsers()
      }, 1000);
      return () => {
          clearInterval(refresher)
      }
    }, [])
    return (
      <div className='users'>
          <table className='users-table'>
              <thead>
                  <tr className='users-table-head'>
                      <th>头像</th>
                      <th>姓名</th>
                      <th>部门</th>
                      <th>企业邮箱</th>
                      <th>已送祝福</th>
                      <th>已收祝福</th>
                      <th>微信名</th>
                      <th>操作</th>
                  </tr>
              </thead>
              <tbody>
                  {users.map((user, key) => {
                      return <tr className='users-table-row' key={key}>
                          <td><img src={user.avatar ? user.avatar : defaultavatar} alt="avatar" /></td>
                          <td>{editing && editKey === key ? <input value={editName} disabled={submitting} onChange={(e)=>setEditName(e.target.value)} /> :user.name}</td>
                          <td>{editing && editKey === key ? <input value={editDepartment} disabled={submitting} onChange={(e)=>setEditDepartment(e.target.value)} /> :user.department}</td>
                          <td>{editing && editKey === key ? <input value={editEmail} disabled={submitting} onChange={(e)=>setEditEmail(e.target.value)} /> :user.email}</td>
                          <td>{user.wishes_done ? '是' : '否'}</td>
                          <td>{user.wishes_received ? '是' : '否'}</td>
                          <td>{user.wechat_name ? user.wechat_name : '未绑定微信'}</td>
                          <td>{editing && editKey === key ?
                              <>
                                  <button className='btn' disabled={submitting} onClick={() => {updateInfo(user.id)}}>提交</button>
                                  {submitting ?
                                      <div className="loader"></div>
                                      :
                                      <button className='btn' onClick={() => {
                                          setEditing(false)
                                          setEditKey(null)
                                      }}>取消</button>
                                  }
                              </>
                              :
                              <>
                                  {user.openid && user.wechat_name && !submitting && <>
                                      <button className='btn' onClick={() => {unBind(user.id)}}>解绑微信</button><br />
                                  </>}
                                  <button
                                      className='btn'
                                      disabled={submitting}
                                      onClick={() => {
                                          setEditing(true)
                                          setEditKey(key)
                                          setEditName(user.name)
                                          setEditDepartment(user.department)
                                          setEditEmail(user.email)
                                      }}>修改资料</button>
                              </>
                          }</td>
                      </tr>
                  })}
              </tbody>
          </table>
      </div>
    );
}

export default UsersManage