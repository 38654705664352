import React, { useRef } from 'react'
import io from 'socket.io-client'
import { useEffect, useState } from 'react'
import './BigScreen.css'
import heart from '../imgs/heart.png'
import energy from '../imgs/energy.png'
import mtFillEmptyBig from '../imgs/mt-fill-empty-big.png'
import axios from 'axios'
import defaultAvatar from '../imgs/defaul_avatar.png'
import BulletScreen, { StyledBullet } from 'rc-bullets'
import QRWarmup from '../imgs/QRWarmup.png'
import QRCheckin from '../imgs/QRCheckin.png'
import BSTitle from '../imgs/bs_title.png'
import QRQuestion from '../imgs/QRQuestion.png'
import QRCharge from '../imgs/QRCharge.png'
import eBigHead from '../imgs/e-big_head.png'
import eBubble from '../imgs/e-bubble.png'
import eHeart from '../imgs/e-heart.png'
import eHumanHead from '../imgs/e-human_head.png'
import eLogo from '../imgs/e-logo.png'
import ePlanet from '../imgs/e-planet.png'
import eSmallHead from '../imgs/e-small_head.png'
import eSPRT from '../imgs/e-sprt.png'
import eStar from '../imgs/e-star.png'
import spiritEmpty from '../imgs/bs-spirit-empty.png'
import spiritFull from '../imgs/bs-spirit-full.png'
import CountdownTimer from './step2/components/CountdownTimer'

let socket
let currentStep
let barHeight = 0
let bulletCounter = 0
let fetchBulletTimer = null
let bulletTimer = null
let fillerWidth = 60
let fillerLeft = 12
let fillerHeight = 0
let fillerRate = 0.3
let screen
let chargeControl
let isFinished = false
const THIRTY_SECONDS_IN_MS = 30 * 1000
let countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS

function BigScreen() {
    const [connection, setConnection] = useState(false)
    const [elementClass, setElementClass] = useState('elements')
    const [phase, setPhase] = useState('')
    const flyingThings = useRef(null)
    const flyingThingsCharging = useRef(null)
    const filler = useRef(null)
    const [bulletStarted, setBulletStarted] = useState(false)
    const [showBulletScreen, setShowBulletScreen] = useState(false)
    const [bar, setBar] = useState(0)
    const stick = useRef(null)
    const [showChargeResult, setShowChargeResult] = useState(false)
    const [chargeResult, setChargeResult] = useState('')

    const getCurrentStage = () => {
        axios.get(window.server + '/api/getstage')
        .then((res) => {
            console.log(res);
            setPhase(res.data.name)
        }).catch((err) => {
            console.log(err);
        })
    }
    const getWishes = async () => {
        try {
            const {data} = await axios.get(window.server + '/api/wishes/listwishtomt/')
            const tempArr = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const fromUser = await getUser(element.fromid)
                if (fromUser && fromUser.name) {
                    element.fromName = fromUser.name
                    element.fromAvatar = fromUser.avatar
                    tempArr.push(element)
                }
            }
            return tempArr
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async (id) => {
        try {
            const res = await axios.get(window.server + '/api/users/' + id)
            return res.data[0]
        } catch (error) {
            console.log(error);
        }
    }
    const startBulletScreen = async () => {
        if(phase === 'checkin') {
            console.log('start bullet');
            setShowBulletScreen(true)
            setBulletStarted(true)
            const wishes = await getWishes()
            if (wishes && wishes.length) {
                if (bulletCounter < wishes.length) {
                    // console.log('wishes going');
                    if (bulletTimer) clearInterval(bulletTimer)
                    bulletTimer = setInterval(() => {
                        if (screen) {
                            if (bulletCounter < wishes.length) {
                                console.log('Got a batch of wishes and adding to bullet screen', bulletCounter);
                                screen.push(
                                    <StyledBullet
                                        head={ wishes[bulletCounter].fromAvatar ?  wishes[bulletCounter].fromAvatar : defaultAvatar}
                                        msg={  wishes[bulletCounter].fromName + '：' + wishes[bulletCounter].content.replace(/(\r?\n|\r)/gm, "")}
                                        size='small'
                                        backgroundColor='rgba(0,0,0,0)'
                                    />
                                )
                                bulletCounter++
                            } else {
                                bulletCounter = 0
                                clearInterval(bulletTimer)
                                startBulletScreen()
                            }
                        }
                    }, 1000);
                } else {
                    console.log('again');
                    clearInterval(bulletTimer)
                    bulletCounter = 0
                    startBulletScreen()
                }
            } else {
                // setTimeout(() => {
                //     console.log('again');
                //     clearInterval(bulletTimer)
                //     bulletCounter = 0
                //     startBulletScreen()
                // }, 2000);
            }
        }
    }
    const stopBulletScreen = async() => {
        if (screen) {
            clearInterval(bulletTimer)
            bulletCounter = 0
            screen.clear()
            screen.pause()
            setBulletStarted(false)
            setShowBulletScreen(false)
            // setScreen(null)
            screen = null
        }

    }
    const connect = () => {
        console.log('Connecting to bigscreen');
        socket.emit("checkin_connect", 'bigscreen')
    };

    const chargeIt = () => {
        // console.log('charging', bar);
        if (!isFinished) {
            if (chargeControl === 1 && barHeight < 20) {
                const temp = bar + 1
                // console.log(temp);
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 50) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.15
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 70) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.1
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 90) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.05
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 95) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.025
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 99) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.01
                barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 1 && barHeight < 100) {
                const temp = bar + 1
                console.log(temp);
                fillerRate = 0.005
                // barHeight += 1 * fillerRate
                setBar(barHeight)
                stick.current.style.height = barHeight + '%'
            } else if (chargeControl === 2 && barHeight < 100) {
                const temp = bar + 1
                // console.log(temp);
                barHeight += 1 * fillerRate
                setBar(barHeight)
            } else {
                barHeight = 100
                setBar(100)
                fillerRate = 0
                setElementClass('elements')
                chargeDoneEmit()
                setTimeout(() => {
                    setShowChargeResult(true)
                    setChargeResult('success')
                }, 1000);
            }
        }
    }
    const timesUp = () => {
        fillerRate = 0
        isFinished = true
        setElementClass('elements')
        chargeDoneEmit()
        if (chargeControl === 1) {
            if (barHeight < 100) {
                setChargeResult('fail')
            } else {
                setChargeResult('success')
            }
            setTimeout(() => {
                setShowChargeResult(true)
            }, 1000);
        } else if (chargeControl === 2) {
            if (barHeight < 100) {
                barHeight = 100
                setBar(100)
            }
            setChargeResult('success')
            fillerRate = 0
            setTimeout(() => {
                setShowChargeResult(true)
            }, 1000);
        }
    }
    const chargeDoneEmit = () => {
        const room = 'bigscreen'
        const command = 'charge_done'
        const value = 1
        socket.emit('admin_connect', {room, command, value})
    }
    const switchFullScreen = async () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.body.requestFullscreen();
        }
        if(screen && bulletStarted) {
            setShowBulletScreen(false)
            setTimeout(() => {
                setShowBulletScreen(true)
            }, 100);
        }
    }

    useEffect(() => {
        if(showBulletScreen) {
            let sc = new BulletScreen('.bullet-screen', {
                duration: 20,
            })
            // setScreen(sc)
            screen = sc
            fetchBulletTimer = setInterval(() => {
                if (bulletCounter === 0 && showBulletScreen) {
                    startBulletScreen()
                }
            }, 1000);
        }

        return () => {
            clearInterval(fetchBulletTimer)
        }
    }, [showBulletScreen])

    useEffect(() => {
        if (stick.current)
        stick.current.style.height = bar + '%'
        return () => {
        }
    }, [bar])
    
    useEffect(() => {
        switch (phase) {
            case 'warmup':
                setElementClass('elements')
                currentStep = 'warmup'
                stopBulletScreen()
                setShowChargeResult(false)
                setChargeResult('')
                barHeight = 0
                setBar(0)
                break;
            case 'checkin':
                currentStep = 'checkin'
                setElementClass('elements')
                setShowBulletScreen(true)
                fillerRate = 0.1667
                startBulletScreen()
                setShowChargeResult(false)
                setChargeResult('')
                barHeight = 0
                setBar(0)
                break;
            case 'question1':
                setElementClass('elements')
                currentStep = 'question1'
                stopBulletScreen()
                setShowChargeResult(false)
                setChargeResult('')
                barHeight = 0
                setBar(0)
                break;
            case 'question2':
                setElementClass('elements')
                currentStep = 'question2'
                stopBulletScreen()
                setShowChargeResult(false)
                setChargeResult('')
                barHeight = 0
                setBar(0)
                break;
            case 'charge':
                currentStep = 'charge'
                stopBulletScreen()
                setShowChargeResult(false)
                setChargeResult('')
                setElementClass('elements')
                isFinished = false
                barHeight = 0
                fillerRate = 0
                chargeControl = 0
                setBar(0)
                break;
        
            default:
                break;
        }
        return () => {
        
        }
    }, [phase])
    
    useEffect(() => {
        // Connect to socket
        socket = io(window.server);
        connect()
        
        // do something when socket connected and disconnected
        socket.on('connect', ()=> {
            console.log('connected')
            setConnection(true)
        })
        socket.on('disconnect', (reason)=> {
            console.log('disconnected', reason)
            setConnection(false)
            socket.connect()
        })

        // hadle progress control
        const changePhase = (data) => {
            console.log(data);
            if (data.p) {
                setPhase(data.p)
                currentStep = data.p
            }
        }
        socket.on("progress", changePhase)

        // handle admin commands
        const adminHandler = async (data) => {
            try {
                console.log(data);
                switch (data.command) {
                    case 'ignite':
                        filler.current.style.width = '60%'
                        filler.current.style.left = '12%'
                        filler.current.style.height = '0'
                        filler.current.classList.add('fill-up')
                        break;
                    case 'lightoff':
                        filler.current.style.width = '60%'
                        filler.current.style.left = '12%'
                        filler.current.style.height = '0'
                        filler.current.className = 'mt-filler'
                        fillerHeight = 0
                        fillerLeft = 12
                        fillerWidth = 60
                        break;
                    case 'clearbullets':
                        if(screen) {
                            console.log('clear');
                            screen.clear()
                        }
                        break;
                    case 'pausebullets':
                        if(screen) screen.pause()
                        break;
                    case 'resumebullets':
                        if(screen) screen.resume()
                        break;
                    case 'hidebullets':
                        if(screen) screen.hide()
                        break;
                    case 'showbullets':
                        if(screen) screen.show()
                        break;
                    case 'start_charging':
                        setBar(0)
                        chargeControl = data.value
                        barHeight = 0
                        fillerRate = 0.3
                        setShowChargeResult(false)
                        setChargeResult('')
                        setElementClass('elements shake')
                        isFinished = false
                        countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                        break;
                    case 'end_charging':
                        timesUp()
                        break;
                    case 'clear_charging':
                        setElementClass('elements')
                        setBar(0)
                        barHeight = 0
                        fillerRate = 0
                        isFinished = true
                        setShowChargeResult(false)
                        setChargeResult('')
                        break;
                    case 'full_charging':
                        barHeight = 100
                        setBar(100)
                        isFinished = true
                        setElementClass('elements')
                        setTimeout(() => {
                            setShowChargeResult(true)
                            setChargeResult('success')
                        }, 1000);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        socket.on("admin_connect", adminHandler)

        // handle checkin
        const checkinHandler = (data) => {
            console.log(data)
            if (flyingThings.current) {
                const newFly = document.createElement("img")
                newFly.src = heart
                newFly.className = 'fly-in'
                newFly.style.left = Math.random() * 100 - 10 + 'vw'
                flyingThings.current.appendChild(newFly)
                setTimeout(() => {
                    if (flyingThings.current) {
                        newFly.className = 'hidden'
                        if (fillerHeight < 40) {
                            fillerWidth += 0.85 * fillerRate
                        } else {
                            fillerWidth += 0.2 * fillerRate
                        }
                        if (fillerWidth < 100) {
                            filler.current.style.width = fillerWidth + '%'
                        } else {
                            fillerWidth = 100
                            filler.current.style.width = fillerWidth + '%'
                        }
                        fillerLeft -= 0.2 * fillerRate
                        if (fillerLeft > 0) {
                            filler.current.style.left = fillerLeft + '%'
                        } else {
                            fillerLeft = 0
                            filler.current.style.left = fillerLeft + '%'
                        }
                        fillerHeight += 1 * fillerRate
                        if (fillerHeight < 100) {
                            filler.current.style.height = fillerHeight + '%'
                        } else {
                            fillerHeight = 100
                            filler.current.style.height = fillerHeight + '%'
                        }
                    }
                }, 2000);
            }
        }
        socket.on("receive_checkin", checkinHandler)

        // handle charging
        const chargingHandler = (data) => {
            // console.log(data);
            // console.log(currentStep);
            if (flyingThingsCharging.current && fillerRate) {
                if (barHeight < 100) {
                    setElementClass('elements shake')
                }
                if (currentStep === 'charge') {
                    // console.log(flyingThings.current.children.length)
                    if (flyingThingsCharging.current.children.length < 40 && fillerRate > 0 && !isFinished) {
                        const newFly = document.createElement("img")
                        newFly.src = energy
                        newFly.className = 'fly-in'
                        newFly.style.left = Math.random() * 100 - 10 + 'vw'
                        flyingThingsCharging.current.appendChild(newFly)
                        setTimeout(() => {
                            if (flyingThingsCharging.current && flyingThingsCharging.current.children.length > 0) {
                                newFly.className = 'hidden'
                                flyingThingsCharging.current.removeChild(newFly)
                                chargeIt()
                            }
                        }, 2000);
                    }
                }
            }
        }
        socket.on("receive_charging", chargingHandler)

        // initialize bullet screen
        setShowBulletScreen(true)
        getCurrentStage()
        return () => {
            // remove listeners and timers
            socket.removeAllListeners()
            clearInterval(bulletTimer)
        }
    }, [])
    return (
        <div className='content bigscreen'>
            {phase === 'warmup' &&
            <div className='bs-checkin'>
                <div className='MT-bigscreen'>
                    <div className='mt-filler-default'></div>
                    <img src={mtFillEmptyBig} alt="heart" className='mt-fill-empty-big' />
                    <span className={connection ? 'connected' : 'disconnected'}></span>
                </div>
                <div className='bigscreen-qr-warmup' onClick={switchFullScreen}>
                    <div className='qr-pole'></div>
                    <div className='qr-plate'>
                        <span>2023年会盛典<br />即将开始<br />扫码互送祝福</span>
                        <img src={QRWarmup} alt="qr" />
                    </div>
                </div>
            </div>
            }
            {phase === 'checkin' &&
            <div className='bs-checkin'>
                <div className='MT-bigscreen'>
                    <div className='mt-filler-default'>
                        <div className='mt-filler' ref={filler}></div>
                    </div>
                    <img src={mtFillEmptyBig} alt="heart" className='mt-fill-empty-big' />
                    <span className={connection ? 'connected' : 'disconnected'}></span>
                    <img src={BSTitle} alt="title" className='bigscreen-checkin-title' />
                </div>
                <div ref={flyingThings}></div>
                <div className='bigscreen-qr-checkin' onClick={switchFullScreen}>
                    <div className='qr-pole'></div>
                    <div className='qr-plate'>
                        <span>一码不扫何以扫天下<br />赶快扫码，<br />开始签到吧！</span>
                        <img src={QRCheckin} alt="qr" />
                    </div>
                </div>
            </div>
            }
            {showBulletScreen &&
                <div className='bullet-screen'></div>
            }
            {(phase === 'question1' || phase === 'question2') &&
            <div className='bs-checkin'>
                <div className='MT-bigscreen'>
                    <div className='mt-filler-default'>
                        <div className='mt-filler fill-up'></div>
                    </div>
                    <img src={mtFillEmptyBig} alt="heart" className='mt-fill-empty-big' />
                    <span className={connection ? 'connected' : 'disconnected'}></span>
                </div>
                <div className='bigscreen-qr-question' onClick={switchFullScreen}>
                    <div className='qr-pole'></div>
                    <div className='qr-plate'>
                        <span>扫描二维码<br />进入答题游戏环节</span>
                        <img src={QRQuestion} alt="qr" />
                    </div>
                </div>
            </div>
            }
            {phase === 'charge' &&
            <div className='bs-charge'>
                <div className={'spirit-bar-frame'}>
                    <div className='spirit-bar' ref={stick}></div>
                </div>
                {bar === 100 ?
                <img src={spiritFull} alt='full' className='spirit-full'></img>
                :
                <img src={spiritEmpty} alt='empty' className='spirit-full'></img>
                }
                <div ref={flyingThingsCharging}></div>
                {/* <div className='bigscreen-qr-charge' onClick={switchFullScreen}>
                    <div className='qr-pole'></div>
                    <div className='qr-plate'>
                        <span>扫描二维码<br />进入为MT蓄能游戏环节</span>
                        <img src={QRCharge} alt="qr" />
                    </div>
                </div> */}
            </div>
            }
            <div className='bs-elements'>
                <img src={eLogo} alt="logo" className={elementClass + ' logo'} />
                <img src={eSPRT} alt="sprt" className={elementClass + ' sprt'} />
                <img src={eHeart} alt="heart" className={elementClass + ' heart'} />
                <img src={eBigHead} alt="bigHead" className={elementClass + ' bighead'} />
                <img src={eSmallHead} alt="smallHead" className={elementClass + ' smallhead'} />
                <img src={eHumanHead} alt="humanHead" className={elementClass + ' humanhead'} />
                <img src={ePlanet} alt="planet" className={elementClass + ' planet'} />
                <img src={eStar} alt="star" className={elementClass + ' star'} />
                <img src={eBubble} alt="bubble" className={elementClass + ' bubble'} />
            </div>
            {showChargeResult === true &&
                <div className='charge-result'>
                    {chargeResult === 'fail' ?
                    <div className='result-content'>
                        <span className='fail-face'></span>
                        <span className='charge-result-title'>非常遗憾<br />本次蓄能失败了</span>
                        <span className='charge-result-content'>各路英雄豪杰们<br />是时候展现你们真正的实力了<br /><br />请拿出六指琴魔般的手速<br />再来一次</span>
                    </div>
                    :
                    <div className='result-content'>
                        <span className='success-face'></span>
                        <span className='charge-result-title'>在大家的齐心协力下<br />蓄能成功！</span>
                        <span className='charge-result-content'>红包在以三千米/秒的速度<br />奔赴你的微信中...</span>
                    </div>
                    }
                </div>
            }
            {elementClass === 'elements shake' &&
                <CountdownTimer targetDate={countDownForThirtySeconds} onEnd = {() => {
                    // timesUp()
                    console.log("time's up");
                }} />
            }
        </div>
    )
}

export default BigScreen