import axios from 'axios'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

function SendWishToMT(props) {
    const [composition, setComposition] = useState("")
    const [cookies, setCookie] = useCookies()
    const [err, setErr] = useState('')
    
    const sendWishes = () => {
        // props.onSent()
        // return
        console.log(composition);
        const sender = cookies.user.id
        console.log(sender);
        if (sender && composition) {
            axios.post(window.server + "/api/wishes/sendtomt", {
                fromid: sender,
                content: composition
            }).then((res) => {
                console.log(res);
                if(res.status === 200) {
                    setCookie('user', res.data, {secure: true, sameSite: 'none'})
                    setComposition('')
                    props.onSent(res.data.changedRows)
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setErr('内容不全')
        }
    }
    return (
        <div className='page wishes-mt'>
            <div className='compose'>
                <div className='edit-area'>
                    <textarea
                        value={composition}
                        maxLength='100'
                        placeholder="请输入你的祝福语"
                        onChange={(e) => {
                            setComposition(e.target.value)
                            setErr('')
                        }}
                        onPaste={e => {
                            let text = e.clipboardData.getData('text')
                            text = text.substring(0, Math.min(text.length(), 100))
                            setComposition(text)
                        }}
                    />
                    <span className='character-count'>{composition.length}/100</span>
                    <button className='send-now btn btn-yellow' onClick={sendWishes}>为MT送上祝福{ props.sent ? '' : <span><br />并领取真诚红包</span>}</button>
                    {err && <span className='form-error'>{err}</span>}
                </div>
            </div>
            { props.sent === 1 && 
                <button className='btn-mt-received btn btn-small btn-yellow' onClick={() => {props.showWishes()}}>MT收到的祝福</button>
            }
        </div>
    )
}

export default SendWishToMT