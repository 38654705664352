import React from 'react'
import io from 'socket.io-client'
import { useEffect, useState } from 'react'
import axios from 'axios'
import './ProgressControl.css'
import UsersManage from './warmup/UsersManage'
import WishesManage from './warmup/WishesManage'
import BulletScreenManage from './checkin/BulletScreenManage'
import QuestionsManage from './question/QuestionsManage'
import CountdownTimer from '../step2/components/CountdownTimer'

let socket
let refreshTarget
const THIRTY_SECONDS_IN_MS = 4 * 30 * 1000
const TEN_MINUTES_IN_MS = 13 * 60 * 1000
let countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
let countDownForTenMinutes = new Date().getTime() + TEN_MINUTES_IN_MS
let chargeDone = false

function ProgressControl() {
    const [connection, setConnection] = useState(false)
    const [phase, setPhase] = useState('')
    const [p, setP] = useState('')
    const [warmupTab, setWarmupTab] = useState('users')
    const [q1Started, setQ1Started] = useState(false)
    const [q2Started, setQ2Started] = useState(false)
    const [charging, setCharging] = useState(false)
    const [chargingClicked, setChargingClicked] = useState(false)
    const [showUsers, setShowUsers] = useState(false)
    const [info, setInfo] = useState('')
    const connect = () => {
        socket.emit("admin_connect", 'bigscreen')
    };
    const getCurrentStage = () => {
        axios.get(window.server + '/api/getstage')
        .then((res) => {
            console.log(res);
            setP(res.data.name)
            refreshTarget = res.data.name
        }).catch((err) => {
            console.log(err);
        })
    }
    const progress = (newP) => {
        setP(newP)
        refreshTarget = newP
    }
    const sendRedPacket = (round) => {
        const name = round === 1 ? 'progressive' : 'responsible'
        axios.post(window.server + '/api/sendquestionsredpacket', {
            name: name
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }
    const sendChargingRedPacket = () => {
        axios.post(window.server + '/api/sendchargeredpacket')
        .then((res) => {
            console.log(res);
            setInfo(res.data)
        }).catch((err) => {
            console.log(err);
        })

    }
    useEffect(() => {
        switch (p) {
            case 'warmup':
                setPhase('预热')
                setCharging(false)
                setChargingClicked(false)
                setQ1Started(false)
                setQ2Started(false)
                setInfo('')
                break;
            case 'checkin':
                setPhase('签到')
                setCharging(false)
                setChargingClicked(false)
                setQ1Started(false)
                setQ2Started(false)
                setInfo('')
                break;
            case 'question1':
                setPhase('答题1')
                setCharging(false)
                setChargingClicked(false)
                setQ1Started(false)
                setQ2Started(false)
                setInfo('')
                if (socket) {
                    const room = 'bigscreen'
                    const command = 'switch_question'
                    const value = 1
                    socket.emit('admin_connect', {room, command, value})
                }
                break;
            case 'question2':
                setPhase('答题2')
                setCharging(false)
                setChargingClicked(false)
                setQ1Started(false)
                setQ2Started(false)
                setInfo('')
                if (socket) {
                    const room = 'bigscreen'
                    const command = 'switch_question'
                    const value = 2
                    socket.emit('admin_connect', {room, command, value})
                }
                break;
            case 'charge':
                setPhase('蓄能')
                setCharging(false)
                setChargingClicked(false)
                setQ1Started(false)
                setQ2Started(false)
                setInfo('')
                break;
            default:
                break;
        }
        const room = 'bigscreen'
        if (socket)
        socket.emit('progress', {p, room})
        return () => {
        }
    }, [p])
    
    useEffect(() => {
        socket = io(window.server)
        connect()
        socket.on('connect', ()=> {
            console.log('connected')
            setConnection(true)
        })
        socket.on('disconnect', (reason)=> {
            console.log('disconnected', reason)
            setConnection(false)
            socket.connect()
        })
        socket.on('admin_connect', (data)=> {
            console.log('admin_connect')
            console.log(data);
            if (data.command === 'charge_done') {
                chargeDone = true
            }
        })
        getCurrentStage()
        return () => {
            socket.removeAllListeners()
        }
    }, [])
    return (
        <div className='page progress-control'>
            <div className='navigation'>
                <h1>当前阶段：{phase}</h1>
                <h5>点击下面的按钮后，系统将整体切换到相应的阶段</h5>
                <div className='nav-btns'>
                    <button
                        className={ p === 'warmup' ? 'btn active' : 'btn'}
                        onClick={() => progress('warmup')}
                    >预热</button>
                    <button
                        className={ p === 'checkin' ? 'btn active' : 'btn'}
                        onClick={() => progress('checkin')}
                    >签到</button>
                    <button
                        className={ p === 'question1' ? 'btn active' : 'btn'}
                        onClick={() => progress('question1')}
                    >答题1</button>
                    <button
                        className={ p === 'question2' ? 'btn active' : 'btn'}
                        onClick={() => progress('question2')}
                    >答题2</button>
                    <button
                        className={ p === 'charge' ? 'btn active' : 'btn'}
                        onClick={() => progress('charge')}
                    >蓄能</button>
                </div>
            </div>
            <div className='more-ops'>
                {p === 'warmup' &&
                <>
                    <div className='censor navigation'>
                        <div className='nav-btns'>
                            <button
                                className={ warmupTab === 'users' ? 'btn active' : 'btn'}
                                onClick={() => {
                                    setWarmupTab('users')
                                    refreshTarget = 'users'
                                }}
                            >所有用户</button>
                            <button
                                className={  warmupTab === 'wishes' ? 'btn active' : 'btn'}
                                onClick={() => {
                                    setWarmupTab('wishes')
                                    refreshTarget = 'wishes'
                                }}
                            >所有祝福</button>
                        </div>
                    </div>
                    {warmupTab === 'users' && <UsersManage />
                    }
                    {warmupTab === 'wishes' && <WishesManage />}
                </>}
                {p === 'checkin' &&
                <>
                    <div className='censor navigation'>
                        <h1>大屏控制</h1>
                        <div className='nav-btns'>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const name = 'admin'
                                socket.emit("send_checkin", {name, room})
                            }}>后台送心</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'ignite'
                                const value = 100
                                socket.emit('admin_connect', {room, command, value})
                            }}>直接点亮</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'lightoff'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>点亮归零</button>
                            <br />
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'clearbullets'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>弹幕清屏</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'pausebullets'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>弹幕暂停</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'resumebullets'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>弹幕继续</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'hidebullets'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>隐藏弹幕</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'showbullets'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>显示弹幕</button>
                        </div>
                    </div>
                    <BulletScreenManage />
                </>}
                {p === 'question1' &&
                <>
                <div className='censor navigation'>
                    <h1>答题控制</h1>
                    <div className='nav-btns'>
                        <button className='btn' onClick={() => {
                            setQ1Started(false)
                            setQ2Started(false)
                            setInfo('')
                            if (socket) {
                                const room = 'bigscreen'
                                const command = 'switch_question'
                                const value = 1
                                socket.emit('admin_connect', {room, command, value})
                            }
                        }}>初始化</button>
                        <button className='btn' onClick={() => {
                            countDownForTenMinutes = new Date().getTime() + TEN_MINUTES_IN_MS
                            setQ1Started(true)
                            const room = 'bigscreen'
                            const command = 'start_question'
                            const value = 1
                            socket.emit('admin_connect', {room, command, value})
                        }}>答题开始</button>
                        <button className='btn' onClick={() => {
                            setQ1Started(false)
                            const room = 'bigscreen'
                            const command = 'end_question'
                            const value = 1
                            socket.emit('admin_connect', {room, command, value})
                        }}>结束（不发红包）</button>
                        <button className='btn' onClick={() => {
                            setQ1Started(false)
                            const room = 'bigscreen'
                            const command = 'end_question'
                            const value = 1
                            socket.emit('admin_connect', {room, command, value})
                            sendRedPacket(1)
                        }}>结束（发放红包）</button>
                    </div>
                    {q1Started ?
                        <CountdownTimer targetDate={countDownForTenMinutes} onEnd = {() => {
                            // setQ1Started(false)
                            console.log("time's up");
                        }} />
                        :
                        <div className='show-counter'>00:00</div>
                    }
                    <hr />
                    <QuestionsManage questionGroup={1} />
                </div>
                </>}
                {p === 'question2' &&
                <>
                <div className='censor navigation'>
                    <h1>答题控制</h1>
                    <div className='nav-btns'>
                        <button className='btn' onClick={() => {
                            setQ1Started(false)
                            setQ2Started(false)
                            setInfo('')
                            if (socket) {
                                const room = 'bigscreen'
                                const command = 'switch_question'
                                const value = 2
                                socket.emit('admin_connect', {room, command, value})
                            }
                        }}>初始化</button>
                        <button className='btn' onClick={() => {
                            countDownForTenMinutes = new Date().getTime() + TEN_MINUTES_IN_MS
                            setQ2Started(true)
                            const room = 'bigscreen'
                            const command = 'start_question'
                            const value = 2
                            socket.emit('admin_connect', {room, command, value})
                        }}>答题开始</button>
                        <button className='btn' onClick={() => {
                            setQ2Started(false)
                            const room = 'bigscreen'
                            const command = 'end_question'
                            const value = 2
                            socket.emit('admin_connect', {room, command, value})
                        }}>结束（不发红包）</button>
                        <button className='btn' onClick={() => {
                            setQ2Started(false)
                            const room = 'bigscreen'
                            const command = 'end_question'
                            const value = 2
                            socket.emit('admin_connect', {room, command, value})
                            sendRedPacket(2)
                        }}>结束（发放红包）</button>
                    </div>
                    {q2Started ?
                        <CountdownTimer targetDate={countDownForTenMinutes} onEnd = {() => {
                            // setQ2Started(false)
                            console.log("time's up");
                        }} />
                        :
                        <div className='show-counter'>00:00</div>
                    }
                    <hr />
                    <QuestionsManage questionGroup={2} />
                </div>
                </>}
                {p === 'charge' &&
                <>
                    <div className='censor navigation'>
                        <h1>蓄能控制</h1>
                        <div className='nav-btns'>
                            <button className='btn' onClick={() => {
                                setCharging(false)
                                setChargingClicked(false)
                                setInfo('')
                                chargeDone = false
                                const room = 'bigscreen'
                                const command = 'clear_charging'
                                const value = 0
                                socket.emit('admin_connect', {room, command, value})
                            }}>初始化</button>
                            <button className='btn' onClick={() => {
                                if (!chargingClicked) {
                                    setChargingClicked(true)
                                    setInfo('')
                                    countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                                    const room = 'bigscreen'
                                    const command = 'start_charging'
                                    const value = 1
                                    chargeDone = false
                                    socket.emit('admin_connect', {room, command, value})
                                    setTimeout(() => {
                                        setCharging(true)
                                    }, 1000);
                                }
                            }}>蓄能开始（必输）</button>
                            <button className='btn' onClick={() => {
                                if(!chargingClicked) {
                                    setChargingClicked(true)
                                    setInfo('')
                                    countDownForThirtySeconds = new Date().getTime() + THIRTY_SECONDS_IN_MS
                                    const room = 'bigscreen'
                                    const command = 'start_charging'
                                    const value = 2
                                    chargeDone = false
                                    socket.emit('admin_connect', {room, command, value})
                                    setTimeout(() => {
                                        setCharging(true)
                                    }, 1000);
                                }
                            }}>蓄能开始（必胜）</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'end_charging'
                                const value = 42
                                chargeDone = false
                                socket.emit('admin_connect', {room, command, value})
                                setTimeout(() => {
                                    setCharging(false)
                                }, 1000);
                            }}>蓄能结束</button>
                            <button className='btn' onClick={() => {
                                setCharging(false)
                                sendChargingRedPacket()
                            }}>发送齐心协力红包</button>
                            <span>{info}</span>
                        </div>
                        {charging ?
                            <CountdownTimer targetDate={countDownForThirtySeconds} onEnd = {() => {
                                setChargingClicked(false)
                                setCharging(false)
                            }} />
                            :
                            <div className='show-counter'>00:00</div>
                        }
                        <h1>特殊控制</h1>
                        <div className='nav-btns'>
                            <button className='btn' onClick={() => {
                                for (let index = 0; index < 10; index++) {
                                    if (!chargeDone) {
                                        setTimeout(() => {
                                            const room = 'bigscreen'
                                            const name = 'admin'
                                            socket.emit("send_charge", {name, room})
                                        }, index * 100);
                                    }
                                }
                            }}>后台送能量(x10)</button>
                            <button className='btn' onClick={() => {
                                if (!chargeDone) {
                                    const room = 'bigscreen'
                                    const name = 'admin'
                                    socket.emit("send_charge", {name, room})
                                }
                            }}>后台送能量(x1)</button>
                            <button className='btn' onClick={() => {
                                const room = 'bigscreen'
                                const command = 'full_charging'
                                const value = 100
                                setCharging(false)
                                socket.emit('admin_connect', {room, command, value})
                            }}>直接充满（必胜）</button>
                        </div>
                    </div>
                </>}
                {p !== 'warmup' &&
                    <div className={showUsers ? 'user-management expanded' : 'user-management'}>
                        <button
                            className={ showUsers ? 'btn active' : 'btn'}
                            onClick={() => {
                                setShowUsers(!showUsers)
                                refreshTarget = 'users'
                            }}
                        >所有用户</button>
                        {showUsers &&
                            <UsersManage />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ProgressControl