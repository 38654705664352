import React, { useEffect } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />:<DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    </div>
  );
};

const CountdownTimer = ({ targetDate, onEnd }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
        onEnd()
    }
  }, [seconds])
  
  return (
    <ShowCounter
    days={days}
    hours={hours}
    minutes={minutes}
    seconds={seconds}
    />
)
};

export default CountdownTimer;
