import axios from 'axios'
import React, { useEffect, useState } from 'react'

function BulletScreenManage() {
    const [users, setUsers] = useState([])
    const [wishesMT, setWishesMT] = useState([])
    const [wishesMTApproved, setWishesMTApproved] = useState([])
    const [wishApproveTab, setWishApproveTab] = useState(false)
    const getUsers = () => {
        console.log('getting users');
        axios.get(window.server + '/api/users')
        .then((res) => {
            console.log(res)
            setUsers(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    // 签到阶段功能
    const getWishesToMT = () => {
        axios.get(window.server + '/api/wishes/listtomt')
        .then((res) => {
            // console.log(res)
            setWishesMT(res.data.filter( wish => wish.approved === 0))
            setWishesMTApproved(res.data.filter( wish => wish.approved === 1))
        }).catch((err) => {
            console.log(err)
        })
    }
    const approve = (wish) => {
        console.log(wish)
        axios.post(window.server + '/api/wishes/approve', {
            id: wish.id
        }).then((res) => {
            console.log(res);
            if (res.data.changedRows)
            setWishesMT(wishesMT.filter( a => a.id !== wish.id))
        }).catch((err) => {
            console.log(err);
        })
    }
    const delWishMT = (wish) => {
        console.log(wish)
        if (window.confirm('确定删除吗？') === true) {
            console.log('yes');
            axios.delete(window.server + '/api/wishes/delete', {
                data: {
                    id: wish.id
                }
            }).then((res) => {
                console.log(res);
                if (res.data.affectedRows)
                setWishesMT(wishesMT.filter( a => a.id !== wish.id))
            }).catch((err) => {
                console.log(err);
            })
        } else {
            console.log('no');
        }
    }
    const reject = (wish) => {
        console.log(wish)
        axios.post(window.server + '/api/wishes/reject', {
            id: wish.id
        }).then((res) => {
            console.log(res);
            if (res.data.changedRows)
            setWishesMTApproved(wishesMTApproved.filter( a => a.id !== wish.id))
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getUsers()
        getWishesToMT()
        let refresher = setInterval(() => {
            getWishesToMT()
        }, 1000);
        return () => {
            clearInterval(refresher)
        }
    }, [])
    
    return (
        <div>
            <div className='censor navigation'>
                <h1>弹幕审核</h1>
                <div className='nav-btns'>
                    <button
                        className={ wishApproveTab ? 'btn' : 'btn active'}
                        onClick={() => {
                            setWishApproveTab(false)
                            getWishesToMT()
                        }}
                    >未通过</button>
                    <button
                        className={ wishApproveTab ? 'btn active' : 'btn'}
                        onClick={() => {
                            setWishApproveTab(true)
                            getWishesToMT()
                        }}
                    >已通过</button>
                </div>
            </div>
            { !wishApproveTab && wishesMT.map((wish) => {
                return <div className='wish' key={wish.id}>
                    <span>来自：{users[wish.fromid - 1] !== undefined && users[wish.fromid - 1].name} </span>
                    <span>审核状态：{wish.approved ? "已通过" : "未通过"}<br /></span>
                    <span className='wish-content'>{wish.content}</span>
                    <div className='censor-btns'>
                        <button className='btn-approve' onClick={() => approve(wish)}>批准</button>
                        <button className='btn-approve' onClick={() => delWishMT(wish)}>删除</button>
                    </div>
                </div>
            })}
            { wishApproveTab && wishesMTApproved.map((wish) => {
                return <div className='wish' key={wish.id}>
                    <span>来自：{users[wish.fromid - 1] !== undefined && users[wish.fromid - 1].name} </span>
                    <span>审核状态：{wish.approved ? "已通过" : "未通过"}<br /></span>
                    <span className='wish-content'>{wish.content}</span>
                    <div className='censor-btns'>
                        <button className='btn-approve' onClick={() => reject(wish)}>驳回</button>
                    </div>
                </div>
            })}
        </div>
    )
}

export default BulletScreenManage