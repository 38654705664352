import axios from 'axios'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

function RedPacket(props) {
    const [cookies] = useCookies()
    const sendRedPacket = (type) => {
        axios.post(window.server + '/api/sendredpacket', {
            openid: cookies.user.openid,
            redpacket: type
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        sendRedPacket('sincere')
        return () => {
        }
    }, [])
    
    return (
        <div className='page red-packet'>
            <button className='btn btn-yellow btn-small btn-mt-wishes' onClick={() => props.close('MTWishes')}>给MT的祝福</button>
            <div className='red-packet-content'>
                <div className='title'>签到成功，收到真诚红包</div>
                <div className='amount'><span>6.66</span>元</div>
                <p className='content'>签到成功喽~<br />感谢亲爱的MTer<br />在过去一年中的付出和努力<br />Captain们已经拉着车、赶着羊，带着真诚的红包和各种大奖，<br />向你飞奔而来！<br />做好准备哦~</p>
                <p className='text-yellow notes'>请在微信中查收</p>
            </div>
        </div>
    )
}

export default RedPacket