import React, { useRef } from 'react'
import io from 'socket.io-client'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import './CheckIn.css'
import defaultAvatar from '../../imgs/defaul_avatar.png'
import mtFillEmpty from '../../imgs/mt-fill-empty.png'
import SendWishToMT from './components/SendWishToMT'
import MTWishes from './components/MTWishes'
import RedPacket from './components/RedPacket'
import axios from 'axios'

let socket

function CheckIn() {
    const [cookies, setCookie] = useCookies()
    const [connection, setConnection] = useState(false)
    const [user, setUser] = useState(cookies.user)
    const [checkedIn, setCheckedIn] = useState(false)
    const mtFiller = useRef(null)
    const [fillUp, setFillUp] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [flyLeft, setFlyLeft] = useState(false)
    const [step, setStep] = useState('')
    const connect = () => {
        socket.emit("checkin_connect", 'bigscreen')
    };

    const closePopup = (feedback) => {
        console.log(feedback);
        setFlyLeft(true)
        setTimeout(() => {
            if(feedback !== 'MTWishes')
            setShowPopup(false)
            setFlyLeft(false)
            setStep(feedback)
        }, 250)
    }
    const checkIn = () => {
        // if(!cookies.user.checked_in) {
            console.log(cookies.user);
            const room = 'bigscreen'
            const name = cookies.user.name
            console.log(name);
            socket.emit("send_checkin", {name, room})
            setFillUp(true)
            
            axios.post(window.server + '/api/checkin', {id: cookies.user.id})
            .then((response) => {
                console.log(response)
                if (response.data !== "already checked in") {
                    setCookie('user', response.data, {secure: true, sameSite: 'none'})
                    setUser(response.data)
                }
                setTimeout(() => {
                    setShowPopup(true)
                    setStep('SendWishToMT')
                    setCheckedIn(true)
                    closePopup()
                }, 750);

            })
            .catch((err) => {
                console.log(err)
            })
        // }
    }
    const wishSent = (changed) => {
        axios.get(window.server + '/api/users/' + cookies.user.id)
        .then((response) => {
            console.log(response)
            setCookie('user', response.data[0], {secure: true, sameSite: 'none'})
            setUser(response.data[0])
            if (changed === 0) {
                setTimeout(() => {
                    setShowPopup(true)
                    setStep('MTWishes')
                }, 250);
            } else {
                setTimeout(() => {
                    setShowPopup(true)
                    setStep('RedPacket')
                }, 250);
            }

        })
        .catch((err) => {
            console.log(err)
        })
    }

    const getTestUser = () => {
        axios.get(window.server + '/api/users/596')
        .then((res) => {
            console.log(res);
            setCookie('user', res.data[0], {secure: true, sameSite: 'none'})
            setUser(res.data[0])
            if (cookies.user)
            cookies.user.checked_in = 0
        })
        .catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        socket = io(window.server)
        connect()
        socket.on('connect', ()=> {
            console.log('connected')
            setConnection(true)
        })
        socket.on('disconnect', (reason)=> {
            console.log('disconnected', reason)
            setConnection(false)
            socket.connect()
        })
        setUser(cookies.user)
        if(cookies.user && cookies.user.checked_in) setCheckedIn(true)
        return () => {
            socket.removeAllListeners()
        }
    }, [])
    return (
        <div className='page checkin'>
            {checkedIn ?
            <SendWishToMT className={flyLeft ? 'fly-left' : ''} sent={user.mt_sent} onSent={wishSent} showWishes={() => {setShowPopup(true); setStep('MTWishes')}}/>
            :
            <div className={flyLeft ? 'page checkin fly-left' : 'page checkin'}>
                <div className='connection-status' onClick={getTestUser}>
                    <span className={connection ? 'connected' : 'disconnected'}></span>
                </div>
                {user && <>
                    <div className='mt-filling'>
                        <div className='mt-filler-default'>
                            
                        </div>
                        <div className={fillUp ? 'mt-filler fill-up' : 'mt-filler'} ref={mtFiller}>
                        </div>
                        <img src={mtFillEmpty} alt="mt-fill" className='mt-fill-empty' />
                    </div>
                    <div className='user-block'>
                        <img src={user.avatar && user.avatar !== 'null' ? user.avatar : defaultAvatar} alt='avatar'/>
                        <span>{user.name}</span>
                    </div>
                    <div className='checkin-btn-area'>
                        <button className='checkin-btn' onClick={checkIn}></button>
                        <span>Hi MTer，点此签到，一起点亮MT吧</span>
                        <p>在签到的同时，别忘记为MT送上你心里祝福哦，<br />你的祝福还可以显示在大屏幕上，<br />快叫上MTer的小伙伴一起玩吧！</p>
                    </div>
                </>
                }
            </div>
            }
            {showPopup && 
                <div className={flyLeft ? 'page fly-left' : 'page pop-up'}>
                    {showPopup && step === 'MTWishes' &&
                        <MTWishes sent={user.mt_sent} close={closePopup}/>
                    }
                    {showPopup && step === 'RedPacket' &&
                        <RedPacket close={closePopup} />
                    }
                </div>
            }
        </div>
    )
}

export default CheckIn