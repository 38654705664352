import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import './Validate.css'
import defaultAvatar from '../../imgs/defaul_avatar.png'

function Validate() {
    const [cookies, setCookie, removeCookie] = useCookies()
    const [user, setUser] = useState(cookies.user)
    const [wechatName, setWechatName] = useState('')
    const [wechatAvatar, setWechatAvatar] = useState('')
    const [openId, setOpenId] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [forceChange, setForceChange] = useState(false)
    const [error, setError] = useState()
    const navigate = useNavigate()
    const { redirect } = useLocation()

    const getUser = (openid) => {
        axios.get(window.server + "/api/getuserbyopenid/" + openid)
        .then( (res) => {
            console.log(res);
            if(res.data[0]) {
                // 如果通过openid能获取到用户信息，则保存到cookies中，并且判断需要跳转到哪里
                setCookie('user', res.data[0], {secure: true, sameSite: 'none'})
                if(cookies.redirect) {
                    navigate(cookies.redirect)
                } else {
                    navigate('/phase1/wishes')
                }
            }
        })
    }
    const getWechatUser = () => {
        // 如果在微信中访问
        if ( navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
            || typeof navigator.wxuserAgent != "undefined"
        ) {
            if (!cookies.wechatinfo) {
                // 如果Cookies中没有微信信息，先判断链接中是否带有code
                console.log('Cookies中没有微信信息，先判断链接中是否带有code');
                var o1 = window.location.href.split("=")
                var o2 = o1[1] ? o1[1].split("&") : ""
                const weChatCode = o2[0]
                console.log(o1, o2, weChatCode);
                // 如果链接中带有code，说明从微信授权页面跳转回来
                if (weChatCode) {
                    // 把code发给后端接口以获取微信信息（后端分两步，先获取access_token再拿到用户信息返给前端）
                    console.log('链接中带有code，把code发给后端接口');
                    axios.post(window.server + '/api/getopenid', {
                        code: weChatCode
                    }).then((res) => {
                        if(res.data.errcode) {
                            // 如果返回错误，则说明要么code有问题，要么access_token过期，不论如何，跳转到授权页面再拿一次code
                            console.log('返回错误，跳转到授权页面再拿一次code');
                            console.log(res.data);
                            window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appid}&redirect_uri=${window.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                        } else {
                            // 用户信息获取成功，存到cookies里
                            console.log('用户信息获取成功，存到cookies里');
                            console.log(res);
                            setCookie('wechatinfo', res.data, {secure: true, sameSite: 'none'})
                            setWechatName(res.data.nickname)
                            setWechatAvatar(res.data.headimgurl)
                            setOpenId(res.data.openid)
                            getUser(res.data.openid)
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                } else if (!cookies.user || !cookies.user.openid) {
                    // 没有微信用户信息，也没有用户信息或用户信息中没有openid，跳转到授权页面再拿一次code
                    window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appid}&redirect_uri=${window.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                }
            }  else if (!cookies.user || !cookies.user.openid) {
                // 如果cookies里有微信信息，但没有用户信息或用户信息中没有openid信息，尝试获取一下用户信息
                console.log('Cookies中有微信信息');
                setWechatName(cookies.wechatinfo.nickname)
                setWechatAvatar(cookies.wechatinfo.headimgurl)
                setOpenId(cookies.wechatinfo.openid)
                getUser(cookies.wechatinfo.openid)
            } else {
                // 微信用户信息和用户信息齐全，说明已完成授权和绑定
                console.log('微信用户信息和用户信息齐全');
                setWechatName(cookies.wechatinfo.nickname)
                setWechatAvatar(cookies.wechatinfo.headimgurl)
                setOpenId(cookies.wechatinfo.openid)
                getUser(cookies.wechatinfo.openid)
            }
        }
    }
    const submit = () => {
        if (!name || !email) {
            setError('请完整填写信息')
        } else {
            axios.post(window.server + '/api/users/validate', {
                name: name,
                email: email,
                wechat_name: wechatName,
                avatar: wechatAvatar,
                openid: openId,
                force: forceChange
            }).then((res)=>{
                console.log(res);
                if (res.status === 200 && res.data === 'user binded') {
                    setError('该用户已绑定微信')
                } else if (res.status === 200 && res.data === 'test binded') {
                    setError('测试用户已绑定微信，如果要重新绑定，请再次点击提交')
                    setForceChange(true)
                } else if (res.status === 200 && res.data === 'updated') {
                    getUserAndRedirect()
                } 
            }).catch((err) => {
                console.log(err);
                setError('您填写的信息与系统中不符，请核实后再试')
            })
        }
    }
    const clearBinding = () => {
        removeCookie('user')
        removeCookie('wechatinfo')
        navigate('/')
    }
    const getUserAndRedirect = () => {
        console.log(cookies.redirect);
        console.log(redirect)
        console.log(openId)
        console.log(cookies.wechatinfo.openid);
        axios.get(window.server + '/api/getuserbyopenid/' + openId)
        .then((res) => {
            console.log(res);
            if(res.data[0] && res.data[0].openid === openId) {
                setCookie('user', res.data[0], {secure: true, sameSite: 'none'})
                if(cookies.redirect) {
                    const tempRedirect = cookies.redirect
                    removeCookie('redirect')
                    navigate(tempRedirect)
                } else if (redirect) {
                    navigate(redirect)
                } else {
                    navigate('/phase1/wishes')
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
      setUser(cookies.user)
      getWechatUser()
      return () => {
        
      }
    }, [])
    
    return (
        <div className='page validate'>
            <h1 className='page-title'>身份验证</h1>
            <div className='user-info'>
                <img src={wechatAvatar ? wechatAvatar : defaultAvatar} alt='avatar' />
                <span>{wechatName}</span>
            </div>
            <div className='validation-form'>
                <div className='form-group'>
                    <label>姓名</label>
                    <input type="text" onChange={(e) => {setError(null); setName(e.target.value)}} />
                </div>
                <div className='form-group'>
                    <label>企业邮箱</label>
                    <input type="email" onChange={(e) => {setError(null); setEmail(e.target.value)}} />
                </div>
                <button onClick={submit} className="btn btn-yellow">提交</button>
                {error && <span className='form-error'>{error}</span>}
                {/* <button onClick={clearBinding} className="btn btn-yellow">清除cookies（测试用，正式版会去掉本按钮）</button> */}

            </div>
            <div className='bottom-info'>
                <span>接下来的活动会涉及以红包发放奖励，因此，请准确填写信息，以便核实身份。</span>
            </div>
        </div>
    )
} 

export default Validate