import axios from 'axios';
import React, { useEffect, useState } from 'react'
import './QuestionsManage.css'
import child1 from '../../../imgs/child1.png'
import child2 from '../../../imgs/child2.png'
import child3 from '../../../imgs/child3.png'
import child4 from '../../../imgs/child4.png'
import mouth1 from '../../../imgs/mouth1.png'
import mouth2 from '../../../imgs/mouth2.png'
import mouth3 from '../../../imgs/mouth3.png'
import mouth4 from '../../../imgs/mouth4.png'

function QuestionsManage(props) {
    const [questionGroup, setQuestionGroup] = useState(props.questionGroup)
    const [questions, setQuestions] = useState([])
    const [tab, setTab] = useState('all')
    const [newType, setNewType] = useState(1)
    const [newQuestionCn, setNewQuestionCN] = useState('')
    const [newQuestionEn, setNewQuestionEN] = useState('')
    const [newChoiceType, setNewChoiceType] = useState(1)
    const [newCorrect, setNewCorrect] = useState([])
    const [newChoiceCN1, setNewChoiceCN1] = useState('')
    const [newChoiceCN2, setNewChoiceCN2] = useState('')
    const [newChoiceCN3, setNewChoiceCN3] = useState('')
    const [newChoiceCN4, setNewChoiceCN4] = useState('')
    const [newChoiceEN1, setNewChoiceEN1] = useState('')
    const [newChoiceEN2, setNewChoiceEN2] = useState('')
    const [newChoiceEN3, setNewChoiceEN3] = useState('')
    const [newChoiceEN4, setNewChoiceEN4] = useState('')
    const [newChoicePic1, setNewChoicePic1] = useState('')
    const [newChoicePic2, setNewChoicePic2] = useState('')
    const [newChoicePic3, setNewChoicePic3] = useState('')
    const [newChoicePic4, setNewChoicePic4] = useState('')
    const [err, setErr] = useState('')
    const getQuestions = () => {
        console.log('getting questions');
        axios.get(window.server + '/api/questions/listgroup/' + questionGroup)
        .then((res) => {
            console.log(res)
            setQuestions(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const updateNewCorrect = (e) => {
        console.log(e.target.value, e.target.checked);
        setNewCorrect([e.target.value])
        console.log(newCorrect);
    }
    const updateNewCorrectMulti = (e) => {
        console.log(e.target.value, e.target.checked);
        if (e.target.checked && !newCorrect.includes(e.target.value) && newType === 2) {
            console.log('多选，添加当前选项到正确答案');
            setNewCorrect([...newCorrect, e.target.value])
        } else if (!e.target.checked && newCorrect.includes(e.target.value)) {
            console.log('多选，从正确答案中移除当前选项');
            setNewCorrect(newCorrect.filter(a => a !== e.target.value))
        }
        console.log(newCorrect);
    }
    const clearForm = () => {
        setNewType(1)
        setNewChoiceType(1)
        setNewQuestionCN('')
        setNewQuestionEN('')
        setNewChoiceCN1('')
        setNewChoiceCN2('')
        setNewChoiceCN3('')
        setNewChoiceCN4('')
        setNewChoiceEN1('')
        setNewChoiceEN2('')
        setNewChoiceEN3('')
        setNewChoiceEN4('')
        setNewChoicePic1('')
        setNewChoicePic2('')
        setNewChoicePic3('')
        setNewChoicePic4('')
        setNewCorrect([])
        setErr('')
    }
    const submitQuestion = () => {
        const question_group = questionGroup
        const type = newType
        const question_cn = newQuestionCn
        const question_en = newQuestionEn
        const choice_type = newChoiceType
        if (newChoiceType === 1 && newQuestionCn && newQuestionEn &&
            newChoiceCN1 && newChoiceCN2 && newChoiceCN3 && newChoiceCN4 &&
            newChoiceEN1 && newChoiceEN2 && newChoiceEN3 && newChoiceEN4 &&
            newCorrect.length
            ) {
                // 文字题，内容齐全
                console.log('文字题，内容齐全')
                const choiceCn = [newChoiceCN1, newChoiceCN2, newChoiceCN3, newChoiceCN4]
                const choiceEn = [newChoiceEN1, newChoiceEN2, newChoiceEN3, newChoiceEN4]
                let right_cn = []
                let right_en = []
                let wrong_cn = []
                let wrong_en = []
                for (let index = 0; index < 4; index++) {
                    const objCn ={
                        id: index + 1,
                        content: choiceCn[index]
                    };
                    const objEn ={
                        id: index + 1,
                        content: choiceEn[index]
                    };
                    if (newCorrect.includes(index + 1 + '')) {
                        right_cn.push(objCn)
                        right_en.push(objEn)
                    } else {
                        wrong_cn.push(objCn)
                        wrong_en.push(objEn)
                    }
                }
                axios.post(window.server + '/api/questions/insert', {
                    question_group: question_group,
                    type: type,
                    question_cn: question_cn,
                    question_en: question_en,
                    choice_type: choice_type,
                    right_cn: right_cn,
                    right_en: right_en,
                    wrong_cn: wrong_cn,
                    wrong_en: wrong_en
                }).then((res) => {
                    console.log(res);
                    if (res.data === 'inserted') {
                        setErr('提交成功')
                        clearForm()
                    }
                }).catch((err) => {
                    console.log(err)
                })
        } else if (newChoiceType === 1 && newQuestionCn && newQuestionEn &&
            newChoiceCN1 && newChoiceCN2 && newChoiceCN3 &&
            newChoiceEN1 && newChoiceEN2 && newChoiceEN3 &&
            newCorrect.length
            ) {
                // 文字题，内容齐全
                console.log('文字题，内容齐全，三个选项')
                const choiceCn = [newChoiceCN1, newChoiceCN2, newChoiceCN3]
                const choiceEn = [newChoiceEN1, newChoiceEN2, newChoiceEN3]
                let right_cn = []
                let right_en = []
                let wrong_cn = []
                let wrong_en = []
                for (let index = 0; index < 3; index++) {
                    const objCn ={
                        id: index + 1,
                        content: choiceCn[index]
                    };
                    const objEn ={
                        id: index + 1,
                        content: choiceEn[index]
                    };
                    if (newCorrect.includes(index + 1 + '')) {
                        right_cn.push(objCn)
                        right_en.push(objEn)
                    } else {
                        wrong_cn.push(objCn)
                        wrong_en.push(objEn)
                    }
                }
                axios.post(window.server + '/api/questions/insert', {
                    question_group: question_group,
                    type: type,
                    question_cn: question_cn,
                    question_en: question_en,
                    choice_type: choice_type,
                    right_cn: right_cn,
                    right_en: right_en,
                    wrong_cn: wrong_cn,
                    wrong_en: wrong_en
                }).then((res) => {
                    console.log(res);
                    if (res.data === 'inserted') {
                        setErr('提交成功')
                        clearForm()
                    }
                }).catch((err) => {
                    console.log(err)
                })
        } else if (newChoiceType === 2 && newQuestionCn && newQuestionEn &&
            newChoicePic1 && newChoicePic2 && newChoicePic3 && newChoicePic4 &&
            newCorrect.length
            ) {
                // 图片题，内容齐全
                console.log('图片题，内容齐全')
                console.log('文字题，内容齐全')
                const choicePic = [newChoicePic1, newChoicePic2, newChoicePic3, newChoicePic4]
                let right_pic = []
                let wrong_pic = []
                for (let index = 0; index < 4; index++) {
                    const objPic ={
                        id: index + 1,
                        content: choicePic[index]
                    };
                    if (newCorrect.includes(index + 1 + '')) {
                        right_pic.push(objPic)
                    } else {
                        wrong_pic.push(objPic)
                    }
                }
                axios.post(window.server + '/api/questions/insert', {
                    question_group: question_group,
                    type: type,
                    question_cn: question_cn,
                    question_en: question_en,
                    choice_type: choice_type,
                    right_pic: right_pic,
                    wrong_pic: wrong_pic,
                }).then((res) => {
                    console.log(res);
                    if (res.data === 'inserted') {
                        setErr('提交成功')
                        clearForm()
                    }
                }).catch((err) => {
                    console.log(err)
                })
        } else {
            setErr('内容不全')
        }
    }
    const delQuestion = (question) => {
        console.log(question);
        if (window.confirm('确定删除吗？') === true) {
            console.log('yes');
            axios.delete(window.server + '/api/questions/delete', {
                data: {
                    id: question.id
                }
            }).then((res) => {
                console.log(res);
                if (res.data.affectedRows)
                setQuestions(questions.filter( a => a.id !== question.id))
            }).catch((err) => {
                console.log(err);
            })
        } else {
            console.log('no');
        }
    }
    const renderChoice = (type, q, key, QArray) => {
        const id = q.id
        const content = q.content
        // console.log(QArray);
        let questionEn
        if (type === 'right') {
            questionEn = JSON.parse(QArray.right_en)
        } else {
            questionEn = JSON.parse(QArray.wrong_en)
        }
        const questionEnContent = questionEn[key].content.includes("\\i") ? <i>{questionEn[key].content.split('\\i')[1]}</i> : questionEn[key].content
        return (
            <span key={key}>
                {id}: {content}
                {content !== questionEnContent ?
                <>
                    <br />{questionEnContent}{key < questionEn.length - 1 ? <br /> : ''}
                </>
                :
                key < questionEn.length - 1 ? <br /> : ''
                }
            </span>
        )
                                    
    }
    const renderPicChoice = (type, q, key, QArray) => {
        const id = q.id
        let content
        switch (q.content) {
            case 'child1':
                content = child1
                break;
            case 'child2':
                content = child2
                break;
            case 'child3':
                content = child3
                break;
            case 'child4':
                content = child4
                break;
            case 'mouth1':
                content = mouth1
                break;
            case 'mouth2':
                content = mouth2
                break;
            case 'mouth3':
                content = mouth3
                break;
            case 'mouth4':
                content = mouth4
                break;
        
            default:
                break;
        }
        let question
        if (type === 'right') {
            question = JSON.parse(QArray.right_pic)
        } else {
            question = JSON.parse(QArray.wrong_pic)
        }
        return (
            <span key={key}>
                {id}: <img src={content} alt={q.content} className="pic-choice"/> {key < question.length - 1 ? <br /> : ''}
            </span>
        )
    }
    useEffect(() => {
        getQuestions()

        return () => {
        }
    }, [])
    
    return (
        <div className='questions-manage navigation'>
            <h1>题库管理</h1>
            <div className='nav-btns'>
                <button
                    className={ tab === 'all' ? 'btn active' : 'btn'}
                    onClick={() => {
                        setTab('all')
                        getQuestions()
                    }}
                >所有题目</button>
                <button
                    className={ tab === 'new' ? 'btn active' : 'btn'}
                    onClick={() => {
                        setTab('new')
                    }}
                >新增题目</button>
            </div>
            {tab === 'new' &&
                <div className='add-question'>
                    <button onClick={clearForm}>清空表格</button>
                    <h2>题目类型：</h2>
                    <span className='inline-input-group'>
                        <label>单选
                            <input
                                type="radio"
                                name="type"
                                value="1"
                                checked={newType === 1}
                                onChange={(e) => {
                                    setNewType(1);
                                    setNewCorrect([])
                                }}
                            />
                        </label>
                        <label>多选
                            <input type="radio" name="type" value="2" checked = {newType === 2} onChange={(e) => setNewType(2)}  />
                        </label>
                    </span>
                    <h2>问题：</h2>
                    <label>中文
                        <input type="text" name="question_cn" value={newQuestionCn} onChange={e => setNewQuestionCN(e.target.value)} />
                    </label>
                    <label>英文
                        <input type="text" name="question_en" value={newQuestionEn} onChange={e => setNewQuestionEN(e.target.value)} />
                    </label>
                    <hr />
                    <h2>选项：</h2>
                    <span>选项类型：</span>
                    <span className='inline-input-group'>
                        <label>文字
                            <input
                                type="radio"
                                name="choice_type"
                                value="1"
                                checked={newChoiceType === 1}
                                onChange={(e) => setNewChoiceType(1)}
                            />
                        </label>
                        <label>图片
                            <input
                                type="radio"
                                name="choice_type"
                                value="2"
                                checked = {newChoiceType === 2}
                                onChange={(e) => setNewChoiceType(2)}
                            />
                        </label>
                    </span>
                    <span>已设置正确答案的序号为： {newCorrect.length > 0 ? newCorrect.map((a,k) => {return k === newCorrect.length - 1 ? a : a + ', '}) : '未设置'}</span>
                    <div className='form-group'>
                        <span>选项1：
                            <label>设置为正确答案
                                {newType === 1 ? 
                                    <input
                                        type="radio"
                                        name="choices"
                                        value="1"
                                        checked={newCorrect.includes('1')}
                                        onChange={updateNewCorrect}
                                    />
                                :
                                    <input
                                        type='checkbox'
                                        name="choicesMulti"
                                        value="1"
                                        checked={newCorrect.includes('1')}
                                        onChange={updateNewCorrectMulti}
                                    />
                                }
                            </label>
                        </span>
                        {newChoiceType === 1 ?
                        <>
                            <label>中文
                                <input type="text" name="choice_cn_1" value={newChoiceCN1} onChange={e => setNewChoiceCN1(e.target.value)} />
                            </label>
                            <label>英文
                                <input type="text" name="choice_en_1" value={newChoiceEN1} onChange={e => setNewChoiceEN1(e.target.value)} />
                            </label>
                        </>
                        :
                        <>
                            <label>图片名（由于图片题不多，暂不开发上传图片功能）
                                <input type="text" name="choice_pic_1" value={newChoicePic1} onChange={e => setNewChoicePic1(e.target.value)} />
                            </label>
                        </>}
                    </div>
                    <div className='form-group'>
                        <span>选项2：
                            <label>设置为正确答案
                                {newType === 1 ? 
                                    <input
                                        type="radio"
                                        name="choices"
                                        value="2"
                                        checked={newCorrect.includes('2')}
                                        onChange={updateNewCorrect}
                                    />
                                :
                                    <input
                                        type='checkbox'
                                        name="choicesMulti"
                                        value="2"
                                        checked={newCorrect.includes('2')}
                                        onChange={updateNewCorrectMulti}
                                    />
                                }
                            </label>
                        </span>
                        {newChoiceType === 1?
                        <>
                            <label>中文
                                <input type="text" name="choice_cn_2" value={newChoiceCN2} onChange={e => setNewChoiceCN2(e.target.value)} />
                            </label>
                            <label>英文
                                <input type="text" name="choice_en_2" value={newChoiceEN2} onChange={e => setNewChoiceEN2(e.target.value)} />
                            </label>
                        </>
                        :
                        <>
                            <label>图片名
                                <input type="text" name="choice_pic_2" value={newChoicePic2} onChange={e => setNewChoicePic2(e.target.value)} />
                            </label>
                        </>}
                    </div>
                    <div className='form-group'>
                        <span>选项3：
                            <label>设置为正确答案
                                {newType === 1 ? 
                                    <input
                                        type="radio"
                                        name="choices"
                                        value="3"
                                        checked={newCorrect.includes('3')}
                                        onChange={updateNewCorrect}
                                    />
                                :
                                    <input
                                        type='checkbox'
                                        name="choicesMulti"
                                        value="3"
                                        checked={newCorrect.includes('3')}
                                        onChange={updateNewCorrectMulti}
                                    />
                                }
                            </label>
                        </span>
                        {newChoiceType === 1?
                        <>
                            <label>中文
                                <input type="text" name="choice_cn_3" value={newChoiceCN3} onChange={e => setNewChoiceCN3(e.target.value)} />
                            </label>
                            <label>英文
                                <input type="text" name="choice_en_3" value={newChoiceEN3} onChange={e => setNewChoiceEN3(e.target.value)} />
                            </label>
                        </>
                        :
                        <>
                            <label>图片名
                                <input type="text" name="choice_pic_3" value={newChoicePic3} onChange={e => setNewChoicePic3(e.target.value)} />
                            </label>
                        </>}
                    </div>
                    <div className='form-group'>
                        <span>选项4：
                            <label>设置为正确答案
                                {newType === 1 ? 
                                    <input
                                        type="radio"
                                        name="choices"
                                        value="4"
                                        checked={newCorrect.includes('4')}
                                        onChange={updateNewCorrect}
                                    />
                                :
                                    <input
                                        type='checkbox'
                                        name="choicesMulti"
                                        value="4"
                                        checked={newCorrect.includes('4')}
                                        onChange={updateNewCorrectMulti}
                                    />
                                }
                            </label>
                        </span>
                        {newChoiceType === 1?
                        <>
                            <label>中文
                                <input type="text" name="choice_cn_4" value={newChoiceCN4} onChange={e => setNewChoiceCN4(e.target.value)} />
                            </label>
                            <label>英文
                                <input type="text" name="choice_en_4" value={newChoiceEN4} onChange={e => setNewChoiceEN4(e.target.value)} />
                            </label>
                        </>
                        :
                        <>
                            <label>图片名
                                <input type="text" name="choice_pic_4" value={newChoicePic4} onChange={e => setNewChoicePic4(e.target.value)} />
                            </label>
                        </>}
                    </div>
                    <button className='btn btn-yellow' onClick={submitQuestion}>提交</button>
                    <span className='error'>{err}</span>
                </div>
            }
            {tab === 'all' &&
            <div className='questions'>
                <table className='questions-table'>
                    <thead>
                        <tr className='questions-table-head'>
                            <th>编号</th>
                            <th>类别</th>
                            <th>问题</th>
                            <th>选项类别</th>
                            <th>正确答案</th>
                            <th>错误答案</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map((question, key) => {
                            return <tr className='questions-table-row' key={key}>
                                <td>{key + 1}</td>
                                <td>{question.type === 1 ? '单选' : '多选'}</td>
                                <td>中文：{question.question_cn}<br />英文：{question.question_en}</td>
                                <td>{question.choice_type === 1 ? '文字题' : '图片题'}</td>
                                <td>
                                    {question.choice_type === 1 && JSON.parse(question.right_cn)
                                    .map((q, key) => renderChoice('right', q, key, question))}
                                    {question.choice_type === 2 && JSON.parse(question.right_pic)
                                    .map((q, key) => renderPicChoice('right', q, key, question))}
                                </td>
                                <td>
                                    {question.choice_type === 1 && JSON.parse(question.wrong_cn)
                                    .map((q, key) => renderChoice('wrong', q, key, question))}
                                    {question.choice_type === 2 && JSON.parse(question.wrong_pic)
                                    .map((q, key) => renderPicChoice('wrong', q, key, question))}
                                </td>
                                <td>
                                    <button
                                      className='btn'
                                      onClick={() => {
                                          delQuestion(question)
                                      }}>删除
                                    </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

export default QuestionsManage