import React, { useEffect, useState } from 'react'
import axios from 'axios'

function WishesManage() {
    const [users, setUsers] = useState([])
    const [allWishes, setAllWishes] = useState([])
    
    const getUsers = () => {
        console.log('getting users');
        axios.get(window.server + '/api/users')
        .then((res) => {
            console.log(res)
            setUsers(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getWishes = () => {
        axios.get(window.server + '/api/wishes/listall')
        .then((res) => {
            console.log(res)
            setAllWishes(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const delWish = (wish) => {
        console.log(wish)
        if (window.confirm('确定删除吗？') === true) {
            console.log('yes');
            axios.delete(window.server + '/api/wishes/delete', {
                data: {
                    id: wish.id
                }
            }).then((res) => {
                console.log(res);
                if (res.data.affectedRows)
                setAllWishes(allWishes.filter( a => a.id !== wish.id))
            }).catch((err) => {
                console.log(err);
            })
        } else {
            console.log('no');
        }
    }
    useEffect(() => {
        getUsers()
        getWishes()
        let refresher = setInterval(() => {
            getWishes()
        }, 1000);
        return () => {
            clearInterval(refresher)
        }
    }, [])
    
    return (
        <div>
            {allWishes.map((wish) => {
                return <div className='wish' key={wish.id}>
                    <span>{users[wish.fromid - 1] !== undefined && users[wish.fromid - 1].name} </span>
                    <span>to {wish.toid > 0 && users[wish.fromid - 1] !== undefined && users[wish.toid - 1].name}<br /></span>
                    <span className='wish-content'>{wish.content}</span>
                    <div className='censor-btns'>
                        <button className='btn-approve' onClick={() => delWish(wish)}>删除</button>
                    </div>
                </div>
            })}
        </div>
    )
}

export default WishesManage