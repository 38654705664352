import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import defaultAvatar from '../../../imgs/defaul_avatar.png'

function ChooseTarget(props) {
    const [keyword, setKeyword] = useState("")
    const [users, setUsers] = useState()
    const [showList, setShowList] = useState(true)
    const [searchResult, setSearchResult] = useState([])
    const [sentList, setSentList] = useState([])
    const [targetList, setTargetList] = useState([])
    const [showButton, setShowButton] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [composition, setComposition] = useState("")
    const [cookie] = useCookies()
    const [err, setErr] = useState('')

    const getSent = async () => {
        try {
            const {data} = await axios.get(window.server + '/api/wishes/listsent/' + cookie.user.id)
            const tempArr = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if(element.toid !== 0) {
                    const toUser = await getUser(element.toid)
                    element.toName = toUser.name
                    element.toAvatar = toUser.avatar
                    element.fromName = cookie.user.name
                    element.fromAvatar = cookie.user.avatar
                    tempArr.push(element)
                }
            }
            return tempArr
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async (id) => {
        try {
            const res = await axios.get(window.server + '/api/users/' + id)
            return res.data[0]
        } catch (error) {
            console.log(error)
        }
    }
    const filterSent = async (userList) => {
        try {
            const sentList = await getSent()
            setSentList(sentList)
            console.log(sentList);
            const newList = []
            for (let index = 0; index < userList.length; index++) {
                const element = userList[index];
                let found = false
                for (let key = 0; key < sentList.length; key++) {
                    const sent = sentList[key];
                    if (element.id === sent.toid) found = true
                }
                if (!found) newList.push(element)
            }
            setUsers(newList)
            setSearchResult(newList)
        } catch (error) {
            console.log(error);
        }

    }
    const updateTargetList = (e) => {
        let list = [...targetList]
        console.log(e.target);
        let position = -1
        for (let index = 0; index < users.length; index++) {
            if (Number(users[index].id) === Number(e.target.value)) {
                position = index
                break
            }
        }
        const target = users[position]
        console.log(target);
        if(!target.checked && targetList.length < 5) {
            list = [...targetList, e.target.value]
            updateChecked(e.target.value, true)
            setErr('')
        } else if(target.checked) {
            e.target.checked = false
            list.splice(targetList.indexOf(e.target.value), 1)
            updateChecked(e.target.value, false)
            setErr('')
        } else {
            setErr('最多选5个')
        }
        console.log(list)
        if(list.length) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
        setTargetList(list)
    }
    const updateChecked = (id, value) => {
        let tempArr = [...users]
        let position = -1
        for (let index = 0; index < tempArr.length; index++) {
            if (Number(tempArr[index].id) === Number(id)) {
                position = index
                break
            }
        }
        if(position > -1) {
            tempArr[position].checked = value
            setUsers(tempArr)
        }
    }
    const sendWishes = () => {
        console.log(composition);
        console.log(targetList);
        const sender = cookie.user.id
        let receivers = targetList
        // targetList.map((target) => {
        //     receivers.push(searchResult[target].id)
        // })
        console.log(sender);
        console.log(receivers);
        if (sender && receivers.length && composition) {
            axios.post(window.server + "/api/wishes/send", {
                fromid: sender,
                toid: receivers,
                content: composition
            }).then((res) => {
                console.log(res);
                if(res.status === 200) {
                    setShowPopup(false)
                    props.close('sent')
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setErr('内容不全')
        }
    }
    useEffect(() => {
        console.log(keyword);
        if(users) {
            if (keyword) {
                const filtered = users.filter(user => {
                    return user.name.includes(keyword)
                })
                setSearchResult(filtered)
            } else {
                setSearchResult(users)
            }
            // setTargetList([])
            // setShowButton(false)
            setShowList(true)
        }
        return () => {
        }
    }, [keyword])

    useEffect(() => {
        axios.get(window.server + "/api/users")
        .then( (res) => {
            console.log(res.data)
            const tempUsers = []
            for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                if (element.id !== cookie.user.id) tempUsers.push(element)
            }
            if (cookie.user.wishes_done === 1 ) {
                filterSent(tempUsers)
            } else {
                setUsers(res.data)
                setSearchResult(tempUsers)
            }
            // setSearchResult(res.data)
        })
        return () => {
      }
    }, [])
    
    return (
        <div className='choose-target'>
            <div className='search-bar'>
                <span>我要送祝福给</span>
                <span>{err}</span>
                <div className='search-form'>
                    <input
                        type="search"
                        placeholder='搜索关键字'
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    {/* <span className='btn-search'></span> */}
                    {keyword && <span className='btn-clear' onClick={() => {
                        setShowList(false)
                        setKeyword('')
                    }}></span>}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25" height="25"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                        onClick={()=>{props.close()}}
                    >
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                            fill="white"
                        ></path>
                    </svg>
                </div>
            </div>
            <div className='user-list'>
                {showList && searchResult.map((item, key) => {
                    return <div key={key} className="user-list-item">
                        <label htmlFor={item.id}>
                            <img src={item.avatar ? item.avatar : defaultAvatar} alt="avatar" className='avatar' />
                            <div className='user-info'>
                                <span className='name'>
                                    {item.name}
                                </span>
                                <span className='info'>
                                    邮箱：{item.email}
                                </span>
                            </div>
                        </label>
                        <input
                            type="checkbox"
                            id={item.id}
                            value={item.id}
                            checked={item.checked ? true : false}
                            onChange={updateTargetList}
                        />
                    </div>
                })}
            </div>
            {showButton && <div className='show-btn'>
                <button
                    className='btn send-wish'
                    onClick={() =>{
                        setErr('')
                        setShowPopup(true)
                    }}
                >{targetList.length > 1 ? '为Ta们祝福' : '为Ta祝福'}</button>
            </div>}
            {showPopup && <div className='page compose pop-up'>
                    <div className='top-bar'>
                        <div className='targets'>
                            <span>TO: </span>
                            {targetList.map((target, key) => {
                                let position = -1
                                for (let index = 0; index < users.length; index++) {
                                    if (Number(users[index].id === Number(target))) {
                                        position = index
                                        break
                                    }
                                }
                                return <span key={key}>{users[position].name}{targetList.length > 1 && key < targetList.length - 1 && '、'}</span>
                            })}
                        </div>
                        <button
                            className='btn choose-again'
                            onClick={() => {
                                setErr('')
                                setShowPopup(false)
                            }}
                        >重选</button>
                    </div>
                    <div className='edit-area'>
                        <textarea
                            value={composition}
                            maxLength='400'
                            placeholder="在此输入你的祝福语"
                            onChange={(e) => {
                                setComposition(e.target.value)
                                setErr('')
                            }}
                        />
                        <span className='character-count'>{composition.length}/400</span>
                        <button className='send-now btn btn-yellow' onClick={sendWishes}>发送祝福</button>
                        {err && <span className='form-error'>{err}</span>}
                    </div>
                </div>
            }
        </div>
    )
}

export default ChooseTarget