import React, { useEffect, useRef, useState } from 'react'
import './Questions.css'
import ruleTitle from '../../imgs/rule_title.png'
import { useCookies } from 'react-cookie'
import defaultAvatar from '../../imgs/defaul_avatar.png'
import bossHead1 from '../../imgs/zack.png'
import bossHead2 from '../../imgs/zack.png'
import CountdownTimer from './components/CountdownTimer'
import axios from 'axios'
import child1 from '../../imgs/child1.png'
import child2 from '../../imgs/child2.png'
import child3 from '../../imgs/child3.png'
import child4 from '../../imgs/child4.png'
import mouth1 from '../../imgs/mouth1.png'
import mouth2 from '../../imgs/mouth2.png'
import mouth3 from '../../imgs/mouth3.png'
import mouth4 from '../../imgs/mouth4.png'
import mouth1half from '../../imgs/mouth1half.png'
import mouth2half from '../../imgs/mouth2half.png'
import mouth3half from '../../imgs/mouth3half.png'
import mouth4half from '../../imgs/mouth4half.png'

const THREE_MINUTES_IN_MS = 10 * 60 * 1000
// const THREE_MINUTES_IN_MS = 5 * 1000;
const ALPHABET = ['A', 'B', 'C', 'D']
let dateTimeAfterThreeMinutes = new Date().getTime() + THREE_MINUTES_IN_MS
let autoNext = 0
function Questions(props) {
    const [cookies, setCookie] = useCookies()
    const [user, setUser] = useState(cookies.user)
    const [questions, setQuestions] = useState([])
    const [isStarted, setIsStarted] = useState(false)
    const [lastRule, setLastRule] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [currentQuestions, setCurrentQuestions] = useState(null)
    const [flyLeft, setFlyLeft] = useState(false)
    const [startCountdown, setStartCountdown] = useState(false)
    const [correctCount, setCorrectCount] = useState(0)
    const [questionNumber, setQuestionNumber] = useState(1)
    const [showResult, setShowResult] = useState(false)
    const bossHead = useRef(null)
    const [questionPool, setQuestionPool] = useState([])
    const [testProgress, setTestProgress] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [currentChoices, setCurrentChoices] = useState([])
    const [userChosen, setUserChosen] = useState([])
    const [checking, setChecking] = useState(false)
    const [message, setMessage] = useState('')
    const [showPrompt, setShowPrompt] = useState(false)
    const submitBtn = useRef(null)
    const nextBtn = useRef(null)
    const getCurrentStage = () => {
        axios.get(window.server + '/api/getstage')
        .then((res) => {
            console.log(res);
            // setPhase(res.data.name)
            // let progress
            if(res.data.name === 'question1') {
                setCurrentQuestions(1)
                // progress = JSON.parse(cookies.test_progress)
                getTestProgress(1)
                // progress = cookies.test_progress
                setLastRule(<span>所有答对10道题以上的同学，即可领取“进取红包”<br /><br />进取红包不抢，全身各处都痒<br />知识就是黄金屋，走起！</span>)
            }
            if(res.data.name === 'question2') {
                setCurrentQuestions(2)
                getTestProgress(2)
                // progress = JSON.parse(cookies.test_progress_2)
                // progress = cookies.test_progress_2
                setLastRule(<span>所有答对10道题以上的同学，即可领取“负责红包”<br /><br />大家加油哟！！！</span>)
            }
            setIsStarted(res.data.started)
            // setCorrectCount(progress.length > 0 ? progress[progress.length - 1].correctCount : 0)
            // if (progress.length > 0 && progress.length >= 20) stopQuestion()
            // setQuestionNumber(progress ? progress.length + 1 : 1)
            dateTimeAfterThreeMinutes = Number(res.data.end_time)
        }).catch((err) => {
            console.log(err);
        })
    }
    const getTestProgress = (round) => {
        axios.get(window.server + '/api/questions/getprogress/' + user.id + '/' + round)
        .then((res) => {
            console.log(res);
            const progress = res.data
            if (round === 1) {
                setCookie('test_progress', progress, {secure: true, sameSite: 'none'})
            } else if (round === 2) {
                setCookie('test_progress_2', progress, {secure: true, sameSite: 'none'})
            }
            setCorrectCount(progress.length > 0 ? progress[progress.length - 1].correctCount : 0)
            if (progress.length >= 20) stopQuestion()
            setQuestionNumber(progress ? progress.length + 1 : 1)
        }).catch((err) => {
            console.log(err);
        })
    }
    const testIni = async (forceClear) => {
        if(forceClear) {
            // 如果强制初始化，则重新生成题库，并清除当前进度
            const qpool = await createQuestionPool()
            const progress = await uploadProgress(null, null, true)
            return qpool
        } else {
            // 是否存在专属题库和当前进度
            if (currentQuestions === 1 && cookies.user && cookies.user.question_pool) {
                console.log('第一轮答题，存在专属题库和当前进度');
                setQuestionPool(JSON.parse(cookies.user.question_pool))
                if (cookies.test_progress) {
                    setTestProgress(cookies.test_progress)
                } else {
                    setTestProgress([])
                }
                return JSON.parse(cookies.user.question_pool)
            }if (currentQuestions === 2 && cookies.user && cookies.user.question_pool_2) {
                console.log('第二轮答题，存在专属题库和当前进度');
                setQuestionPool(JSON.parse(cookies.user.question_pool_2))
                if (cookies.test_progress_2) {
                    setTestProgress(cookies.test_progress_2)
                } else {
                    setTestProgress([])
                }
                return JSON.parse(cookies.user.question_pool_2)
            } else {
                console.log('创建专属题库');
                const qpool = await createQuestionPool()
                return qpool
            }
        }
    }
    const getQuestions = async () => {
        try {
            console.log('getting questions');
            const res = await axios.get(window.server + '/api/questions/listgroup/' + currentQuestions)
            if (res.data.length) {
                console.log('questions fetched', res.data);
                setQuestions(res.data)
                return res.data
            }
        } catch (error) {
            return error
        }

    }
    const createQuestionPool = async () => {
        try {
            const qs = await getQuestions()
            if (qs.length) {
                const questionIds = []
                qs.map((q, k) => {
                    questionIds.push(q.id)
                })
                const shuffledQs = shuffleIt(questionIds)
                setQuestionPool(shuffledQs)
                saveQuestionPool(shuffledQs)
                return shuffledQs
            }
        } catch (error) {
            console.log(error)
            
        }
    }
    const shuffleIt = (array) => {
        var i = array.length,
            j = 0,
            temp;
        while (i--) {
            j = Math.floor(Math.random() * (i+1));
            temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
    const saveQuestionPool = (question_pool) => {
        axios.post(window.server + '/api/questions/savequestionpool', {
            id: user.id,
            data: question_pool,
            round: currentQuestions
        }).then((res) => {
            console.log(res);
            if (res.data.id) {
                setUser(res.data)
                setCookie('user', res.data, {secure: true, sameSite: 'none'})
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const startTest = async(forceIni) => {
        try {
            console.log('starting', forceIni);
            setUserChosen([])
            setChecking(false)
            if (submitBtn.current) {
                submitBtn.current.setAttribute("disabled", true)
            }
            if(forceIni) {
                setQuestionNumber(1)
                const qpool = await testIni(true)
                console.log('back to startTest', questions);
                setFlyLeft(false)
                setShowPopup(true)
                dateTimeAfterThreeMinutes = new Date().getTime() + THREE_MINUTES_IN_MS
                setStartCountdown(true)
                // 按进度抽取专属题库中的一道题
                let getCurrentQ
                let progress = []
                if (currentQuestions === 1){
                    setCookie('test_progress', progress, {secure: true, sameSite: 'none'})
                } else if (currentQuestions === 2){
                    setCookie('test_progress_2', progress, {secure: true, sameSite: 'none'})
                }
                getCurrentQ = questions.find(obj => obj.id === qpool[ progress.length])
                console.log('当前题目', getCurrentQ);
                console.log('当前题目库', qpool);
                console.log('当前进度', cookies.test_progress, progress.length);
                console.log('当前题目序号', qpool[progress.length]);
                assembleChoices(getCurrentQ)
                setCurrentQuestion(getCurrentQ)
            } else if (isStarted) {
                setFlyLeft(false)
                setShowPopup(true)
                setStartCountdown(true)
                const qpool = await testIni(false)
                // 按进度抽取专属题库中的一道题
                let getCurrentQ
                let progress
                if (currentQuestions === 1){
                    progress = cookies.test_progress ? cookies.test_progress : []
                } else if (currentQuestions === 2){
                    progress = cookies.test_progress_2 ? cookies.test_progress_2 : []
                }
                getCurrentQ = questions.find(obj => obj.id === qpool[progress ? progress.length : 0])
                console.log('当前题目', getCurrentQ);
                console.log('当前题目库', qpool);
                console.log('当前进度', cookies.test_progress, progress.length);
                console.log('当前题目序号', qpool[progress.length]);
                assembleChoices(getCurrentQ)
                setCurrentQuestion(getCurrentQ)
                setQuestionNumber(progress? progress.length + 1 : 1)
            } else {
                setShowAlert(true)
                setTimeout(()=>{setShowAlert(false)}, 2000)
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    const assembleChoices = (q) => {
        if (q.choice_type === 1) {
            // 文字题
            const right_cn = JSON.parse(q.right_cn)
            const right_en = JSON.parse(q.right_en)
            const wrong_en = JSON.parse(q.wrong_en)
            const wrong_cn = JSON.parse(q.wrong_cn)
            let combined_choices = []
            right_cn.map((choice, key) => {
                const newObj = {
                    id: choice.id,
                    cn: choice.content,
                    en: right_en[key].content,
                    mark: 'right'
                }
                combined_choices.push(newObj)
            })
            wrong_cn.map((choice, key) => {
                const newObj = {
                    id: choice.id,
                    cn: choice.content,
                    en: wrong_en[key].content,
                    mark: 'wrong'
                }
                combined_choices.push(newObj)
            })
            let arr = []
            for (let index = 0; index < right_cn.length + wrong_cn.length; index++) {
                arr.push(index)
            }
            const randomizeIt = shuffleIt(arr)
            let randomizedChoices = []
            randomizeIt.map((i) => {
                randomizedChoices.push(combined_choices[i])
            })
            console.log(randomizedChoices);
            setCurrentChoices(randomizedChoices)
            // if(submitBtn.current) {
            //     submitBtn.current.disabled = false
            // }
        } else if (q.choice_type === 2) {
            // 图片题
            const right_pic = JSON.parse(q.right_pic)
            const wrong_pic = JSON.parse(q.wrong_pic)
            let combined_choices = []
            right_pic.map((choice, key) => {
                let content
                let result = null
                switch (choice.content) {
                    case 'child1':
                        content = child1
                        break;
                    case 'child2':
                        content = child2
                        break;
                    case 'child3':
                        content = child3
                        break;
                    case 'child4':
                        content = child4
                        break;
                    case 'mouth1':
                        content = mouth1half
                        result = mouth1
                        break;
                    case 'mouth2':
                        content = mouth2half
                        result = mouth2
                        break;
                    case 'mouth3':
                        content = mouth3half
                        result = mouth3
                        break;
                    case 'mouth4':
                        content = mouth4half
                        result = mouth4
                        break;
                
                    default:
                        break;
                }
                const newObj = {
                    id: choice.id,
                    img: content,
                    img2: result,
                    mark: 'right'
                }
                combined_choices.push(newObj)
            })
            wrong_pic.map((choice, key) => {
                let content
                let result = null
                switch (choice.content) {
                    case 'child1':
                        content = child1
                        break;
                    case 'child2':
                        content = child2
                        break;
                    case 'child3':
                        content = child3
                        break;
                    case 'child4':
                        content = child4
                        break;
                    case 'mouth1':
                        content = mouth1half
                        result = mouth1
                        break;
                    case 'mouth2':
                        content = mouth2half
                        result = mouth2
                        break;
                    case 'mouth3':
                        content = mouth3half
                        result = mouth3
                        break;
                    case 'mouth4':
                        content = mouth4half
                        result = mouth4
                        break;
                
                    default:
                        break;
                }
                const newObj = {
                    id: choice.id,
                    img: content,
                    img2: result,
                    mark: 'wrong'
                }
                combined_choices.push(newObj)
            })
            let arr = []
            for (let index = 0; index < right_pic.length + wrong_pic.length; index++) {
                arr.push(index)
            }
            const randomizeIt = shuffleIt(arr)
            let randomizedChoices = []
            randomizeIt.map((i) => {
                randomizedChoices.push(combined_choices[i])
            })
            console.log(randomizedChoices);
            setCurrentChoices(randomizedChoices)
            // if(submitBtn.current) {
            //     submitBtn.current.disabled = false
            // }
        }
    }

    const updateUserChosen = (e) => {
        if (currentQuestion.type === 1) {
            console.log(e.target.value, e.target.checked);
            setUserChosen([e.target.value])
            console.log(userChosen);
        } else if (currentQuestion.type === 2) {
            console.log(e.target.value, e.target.checked);
            if (e.target.checked && !userChosen.includes(e.target.value)) {
                console.log('多选，添加当前选项到答案');
                setUserChosen([...userChosen, e.target.value])
            } else if (!e.target.checked && userChosen.includes(e.target.value)) {
                console.log('多选，从答案中移除当前选项');
                setUserChosen(userChosen.filter(a => a !== e.target.value))
            }
        }
    }
    useEffect(() => {
        if (submitBtn.current) {
            if (userChosen.length) {
                console.log('not disabled');
                submitBtn.current.removeAttribute("disabled")
            } else {
                console.log('disabled');
                submitBtn.current.setAttribute("disabled", true)
            }
        }

        return () => {
        }
    }, [userChosen])
    
    const submitChoice = () => {
        submitBtn.current.setAttribute("disabled", true)
        if(userChosen.length) {
            let result = '正确 Correct'
            let correctAnswers = 0
            for (let i = 0; i < userChosen.length; i++) {
                const u = userChosen[i];
                for (let j = 0; j < currentChoices.length; j++) {
                    const c = currentChoices[j];
                    if(c.id.toString() === u && c.mark === 'wrong') {
                        result = '错误 Wrong'
                        break;
                    }
                    if (c.mark === 'right') correctAnswers++
                }
            }
            console.log(userChosen.length, correctAnswers);
            if (userChosen.length < correctAnswers / userChosen.length) result = '错误 Wrong'
            if(result === '正确 Correct') {
                setCorrectCount(correctCount + 1)
                if (correctCount + 1 === 10) {
                    setMessage('小小红包已经拿到手喽！继续加油，冲进更大奖池！')
                    setShowPrompt(true)
                    autoNext = 2000
                } else {
                    setMessage(result)
                    autoNext = 500
                }
                // setTimeout(() => {
                //     setShowPrompt(false)
                //     setMessage('')
                // }, 1000);
                uploadProgress(result, correctCount + 1)
            } else {
                uploadProgress(result, correctCount)
                autoNext = 0
            }
            setChecking(true)
        } else {
            setMessage('请做出选择后再点“确定”')
            // if(submitBtn.current) {
            //     submitBtn.current.disabled = false
            // }
        }
    }
    const nextQuestion = () => {
        nextBtn.current.setAttribute('disabled', true)
        if(checking && testProgress.length < 20) {
            let qpool
            let getCurrentQ
            let progress
            if (currentQuestions === 1){
                progress = cookies.test_progress
                qpool = JSON.parse(cookies.user.question_pool)
            } else if (currentQuestions === 2){
                progress = cookies.test_progress_2
                qpool = JSON.parse(cookies.user.question_pool_2)
            }
            getCurrentQ = questions.find(obj => obj.id === qpool[ progress.length])
            console.log('当前题目', getCurrentQ);
            console.log('当前题目库', qpool);
            console.log('当前进度', cookies.test_progress, progress.length);
            console.log('当前题目序号', qpool[progress.length]);
            console.log(getCurrentQ);
            assembleChoices(getCurrentQ)
            setCurrentQuestion(getCurrentQ)
            setChecking(false)
            setUserChosen([])
            setQuestionNumber(testProgress.length + 1)
        }
    }
    const sendRedPacket = (type) => {
        axios.post(window.server + '/api/sendredpacket', {
            openid: cookies.user.openid,
            redpacket: type
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (checking && autoNext > 0) {
            setTimeout(() => {
                setShowPrompt(false)
                setMessage('')
                // submitChoice()
                nextQuestion()
            }, autoNext);
        }
        if(nextBtn.current) {
            nextBtn.current.removeAttribute('disabled')
        }
        return () => {
        }
    }, [testProgress])
    
    const uploadProgress = async (result, correct, clear) => {
        let progress = []
        if(!clear){
            const progressObj = {
                question_id: currentQuestion.id,
                result: result,
                choice: userChosen,
                correctCount: correct,
                round: currentQuestions
            }
            progress = [...testProgress, progressObj]
        }
        console.log('uploading progress', progress);
        axios.post(window.server + '/api/questions/saveprogress', {
            id: user.id,
            progress: progress,
            round: currentQuestions
        }).then((res) => {
            console.log(res);
            if (currentQuestions === 1) {
                setCookie('test_progress', res.data, {secure: true, sameSite: 'none'})
                if (correct === 15) {
                    sendRedPacket('progressive2')
                } else if (correct === 10) {
                    sendRedPacket('progressive1')
                }
            } else if (currentQuestions === 2) {
                setCookie('test_progress_2', res.data, {secure: true, sameSite: 'none'})
                if (correct === 15) {
                    sendRedPacket('responsible2')
                } else if (correct === 10) {
                    sendRedPacket('responsible1')
                }
            }
            const progress = res.data
            const count = progress.length ? progress[progress.length - 1].correctCount : 0
            setCorrectCount(count)
            setTestProgress(progress)
            if (progress.length >= 20) {
                stopQuestion()
            } else {
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const timesUp = () => {
        console.log('times up!');
        setStartCountdown(false)
        setFlyLeft(true)
        if(submitBtn.current) {
            submitBtn.current.disabled = true
        }
        setTimeout(() => {
            setShowPopup(false)
            setShowResult(true)
        }, 250)
    }
    const stopQuestion = () => {
        setIsStarted(false)
        if(submitBtn.current) {
            submitBtn.current.disabled = true
        }
        setTimeout(() => {
            setFlyLeft(true)
            setShowResult(true)
            setTimeout(() => {
                setShowPopup(false)
            }, 250)
        }, 500)
    }

    useEffect(() => {
        console.log(props);
        if (props.adminCmd) {
            switch (props.adminCmd.command) {
                case 'switch_question':
                    setIsStarted(false)
                    setFlyLeft(false)
                    setShowPopup(false)
                    setShowAlert(false)
                    setStartCountdown(false)
                    setShowResult(false)
                    if (props.adminCmd.value === 1) {
                        setCurrentQuestions(1)
                        setLastRule(<span>所有答对10道题以上的同学，即可领取“进取红包”<br /><br />进取红包不抢，全身各处都痒<br />知识就是黄金屋，走起！</span>)
                    } else {
                        setCurrentQuestions(2)
                        setLastRule(<span>所有答对10道题以上的同学，即可领取“负责红包”<br /><br />大家加油哟！！！</span>)
                    }
                    break;
                case 'start_question':
                    setCurrentQuestions(1)
                    setIsStarted(true)
                    if (props.adminCmd.value === 1) {
                        setCurrentQuestions(1)
                        setLastRule(<span>所有答对10道题以上的同学，即可领取“进取红包”<br /><br />进取红包不抢，全身各处都痒<br />知识就是黄金屋，走起！</span>)
                    } else {
                        setCurrentQuestions(2)
                        setLastRule(<span>所有答对10道题以上的同学，即可领取“负责红包”<br /><br />大家加油哟！！！</span>)
                    }
                    startTest(true)
                    break;
                case 'end_question':
                    setCurrentQuestions(1)
                    setIsStarted(false)
                    setFlyLeft(true)
                    if (props.adminCmd.value === 1) {
                        setCurrentQuestions(1)
                        setShowResult(true)
                    } else {
                        setCurrentQuestions(2)
                        setShowResult(true)
                    }
                    setTimeout(() => {
                        setShowPopup(false)
                    }, 250)
                    break;
            
            
                default:
                    break;
            }
        }
        return () => {
        }
    }, [props])
    
    useEffect(() => {
        getQuestions()
        return () => {
        }
    }, [currentQuestions])
    
    useEffect(() => {
        setShowPopup(false)
        setUser(cookies.user)
        setShowResult(false)
        getCurrentStage()
        let isHead1 = true
        let boss_head_timer = setInterval(() => {
            if (bossHead.current) {
                if (isHead1) {
                    isHead1 = false
                    bossHead.current.src = bossHead2
                } else {
                    isHead1 = true
                    bossHead.current.src = bossHead1
                }
            }
        }, 1000);
        return () => {
            clearInterval(boss_head_timer)
        }
    }, [])
    return (
        <div className='page questions'>
            <img src={ruleTitle} alt='rules title' className='rule-title' />
            <div className='rules'>
                <ul>
                    <li>本环节共有20道题，限时10分钟完成</li>
                    <li>点击“开始答题”后，则倒计时开始</li>
                    <li>“确定”后即为提交答案，不可更改</li>
                    <li>{lastRule}</li>
                </ul>
            </div>
            <button className='btn btn-yellow' onClick={() => startTest(false)}>点击此处  开始答题</button>
            {showAlert && 
                <div className='page alert'>
                    <span>游戏还未开始<br /></span>
                    <span>请等候主持人口令</span>
                </div>
            }
            {showPopup &&
                <div className={flyLeft ? 'page fly-left' : 'page pop-up'}>
                    <div className='user'>
                        <img src={user.avatar ? user.avatar : defaultAvatar} alt="avatar" className='avatar' />
                        {user.name}
                    </div>
                    <div className='countdown-timer'>
                        {startCountdown ?
                            <CountdownTimer targetDate={dateTimeAfterThreeMinutes} onEnd = {() => timesUp()} />
                            :
                            <span className='countdown danger'>00:00</span>
                        }
                    </div>
                    <div className='correct-count'>
                        <span>已答对：{correctCount}道题<br />共20题</span>
                    </div>
                    <div className='question-area'>
                        <div className='question'>
                            <div className='question-count'>
                                第{questionNumber}题
                            </div>
                            <div className='question-description'>
                                <div className='chinese'>
                                    { currentQuestion && currentQuestion.question_cn}
                                </div>
                                <div className='english'>
                                    { currentQuestion && currentQuestion.question_en}
                                </div>
                            </div>
                            <div className={checking ? 'question-choices checking' : 'question-choices'}>
                                
                                {currentQuestion !== null && currentQuestion.choice_type === 1 &&
                                    <div className={currentQuestion.type === 1 ? 'text' : 'text multiple'}>
                                        { currentChoices.length && currentChoices.map((c,k) => {
                                            return (
                                                <label className={userChosen.includes(c.id.toString()) ? 'choice chosen '  + c.mark: 'choice ' + c.mark} key={k}>
                                                    <span className='check-circle'></span>
                                                    <input
                                                        type={currentQuestion.type === 1 ? "radio" : 'checkbox'}
                                                        name={currentQuestion.id}
                                                        value={c.id}
                                                        disabled={checking}
                                                        checked={userChosen.includes(c.id.toString())}
                                                        onChange={updateUserChosen}
                                                        className={ userChosen.includes(c.id.toString()) ? 'chosen ' + c.mark : c.mark}
                                                    />
                                                    <span>{ALPHABET[k]}</span>
                                                    <span className='chinese'>{c.cn}</span>{c.cn !== c.en && <span className='english'>{c.en.includes("\\i") ? <i>{c.en.split('\\i')[1]}</i> : c.en}</span>}
                                                </label>
                                            )
                                        })}
                                    </div>
                                }
                                {currentQuestion !== null && currentQuestion.choice_type === 2 &&
                                <div className={currentQuestion.type === 1 ? 'pics' : 'pics multiple'}>
                                    { currentChoices.length && currentChoices.map((c,k) => {
                                        return (
                                            <label className={userChosen.includes(c.id.toString()) ? 'choice chosen '  + c.mark: 'choice ' + c.mark} key={k}>
                                                <span className='serial-number'>{ALPHABET[k]}</span>
                                                <img src={c.img} alt="choice image" className='choice-img' />
                                                <input
                                                    type={currentQuestion.type === 1 ? "radio" : 'checkbox'}
                                                    name={currentQuestion.id}
                                                    value={c.id}
                                                    disabled={checking}
                                                    checked={userChosen.includes(c.id.toString())}
                                                    onChange={updateUserChosen}
                                                    className={userChosen.includes(c.id.toString()) ? 'chosen ' + c.mark : c.mark}
                                                />
                                                <span className='check-circle'></span>
                                            </label>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>
                        {checking ? 
                            <button
                                className='btn btn-yellow'
                                onClick={nextQuestion}
                                ref={nextBtn}
                            >下一题 Next</button>
                            :
                            <button
                                className='btn btn-yellow'
                                onClick={submitChoice}
                                // disabled={!isStarted}
                                ref={submitBtn}
                            >确定 Comfirm</button>
                        }
                    </div>
                    {message === '正确 Correct' &&
                        <div className='result-prompt' onClick={() => setMessage('')}>
                            <div className='message'>
                                <span className='smilie-face'></span>
                                <span>{message}</span>
                            </div>
                        </div>
                    }
                    {showPrompt && 
                        <div
                            className='result-prompt'
                            onClick={() => {
                                setShowPrompt(false)
                                setMessage('')
                            }}>
                            <div className='message'>
                                <span className='smilie-face'></span>
                                <span>{message}</span>
                            </div>
                        </div>
                    }
                </div>
            }
            {showResult &&
                <div className='page result'>
                    {correctCount >= 10 &&
                        <div className='page red-packet'>
                            {currentQuestions === 1 && correctCount >= 15 && 
                                <div className='red-packet-content'>
                                    <span className='smile'></span>
                                    <span className='content'>没想到你还挺厉害的，<br />2个进取红包<br />正在赶来的路上！</span>
                                    <span className='text-yellow notes'>请注意在微信服务通知中查收哦。</span>
                                </div>
                            }
                            {currentQuestions === 1 && correctCount >= 10 && correctCount < 15 &&
                                <div className='red-packet-content'>
                                    <span className='smile'></span>
                                    <span className='content'>据不完全统计<br />能答对10道题<br />已经是小天才了！</span>
                                    <span className='text-yellow notes'>请在微信服务通知中<br />查收专属你的进取红包吧！</span>
                                </div>
                            }
                            {currentQuestions === 2 && correctCount >= 15 &&
                                <div className='red-packet-content'>
                                    <span className='smile'></span>
                                    <span className='content'>没想到你还挺厉害的，<br />2个负责红包<br />正在赶来的路上！</span>
                                    <span className='text-yellow notes'>请注意在微信服务通知中查收哦。</span>
                                </div>
                            }
                            {currentQuestions === 2 && correctCount >= 10 && correctCount < 15 &&
                                <div className='red-packet-content'>
                                    <span className='smile'></span>
                                    <span className='content'>据不完全统计<br />能答对10道题<br />已经是小天才了！</span>
                                    <span className='text-yellow notes'>请在微信服务通知中<br />查收专属你的负责红包吧！</span>
                                </div>
                            }
                        </div>
                    }
                    
                    {correctCount < 10 && 
                        <span className='correct-count-text'>答题结束<br />你共答对了<br />{correctCount}道题！</span>
                    }
                    {/* {currentQuestions === 2 && correctCount >= 10 && <span className='additional-compliment'>恭喜你<br />你凭借优秀的实力，<br />获得了领取“负责红包”的资格！</span>} */}
                    {correctCount < 10 && 
                    <div className={currentQuestions === 2 && correctCount < 10 ? 'result-bubble fail' : 'result-bubble'}>
                        {currentQuestions === 1 && correctCount < 10 && <span>虽然有点遗憾，但，<br />遗憾也是快乐回忆的一部分<br />稍后还有一轮<br />请收起小桌板，打开遮光板，<br />系好安全带，<br />我们即将降落在<br />“红包大陆”</span>}
                        {currentQuestions === 2 && correctCount < 10 && <span>听，这是红包从你身边飞过的声音<br />很遗憾，<br />你与“负责红包”擦身而过<br />但我们相信，更大的“红包”<br />在生活的某处等着你<br />时刻准备着<br />给你一个出其不意的惊喜<br /><br />我们一起期待！</span>}
                    </div>
                    }
                     {correctCount < 10 && <img src={bossHead1} ref={bossHead} alt='bosshead' className='boss-head' />}
                    {/* {currentQuestions === 2 && correctCount >= 8 && <span className='additional-description'>你的负责红包已测体温、酒精消毒，稍后会发送至你微信，请在微信中放心查收。</span>} */}
                </div>
            }
        </div>
    )
}

export default Questions