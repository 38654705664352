import React from 'react'
import { useEffect, useState } from 'react'
import io from 'socket.io-client'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useNavigate, useLocation } from 'react-router-dom'

let socket

function Login(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const [cookies, setCookie, removeCookie] = useCookies(['user'])
    const [user, setUser] = useState(cookies.user)
    
    const connect = () => {
        socket.emit("admin_connect", 'bigscreen')
    };

    const getUser = (openid) => {
      axios.get(window.server + "/api/getuserbyopenid/" + openid)
      .then( (res) => {
        console.log(res);
        if(res.data[0]) {
            // 如果通过openid能获取到用户信息，则保存到cookies中
            setCookie('user', res.data[0], {secure: true, sameSite: 'none'})
            if(location.pathname === '/') {
                // 如果是在授权页，则判断需要跳转到哪里
                if(cookies.redirect) {
                    navigate(cookies.redirect)
                } else {
                    navigate('/phase1/wishes')
                }
            }
        } else {
            if(location.pathname !== '/') {
                // 不在授权页，如果获取不到用户信息，则说明没有绑定（要么没完成绑定，要么被管理员解绑了），把cookies清空一下重新授权
                removeCookie('user')
                removeCookie('wechatinfo')
                if( !cookies.redirect ) setCookie('redirect', location.pathname, {secure: true, sameSite: 'none'})
                window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appid}&redirect_uri=${window.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            }
        }
      })
    }

    useEffect(() => {
        // 如果是在微信中访问
        if ( navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 || typeof navigator.wxuserAgent != "undefined") {
            if (location.pathname !== '/bigscreen' && location.pathname !== '/admin/control') {
                // 大屏和后台管理页面就不用微信授权了
                if (!cookies.wechatinfo) {
                    // 如果cookies里没有微信信息则跳转微信授权页面，先把跳转前的路径存在cookies中，以便完成绑定之后再跳回去
                    if( !cookies.redirect ) setCookie('redirect', location.pathname, {secure: true, sameSite: 'none'})
                    window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appid}&redirect_uri=${window.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                } else if (location.pathname !== '/' && (!cookies.user || !cookies.user.openid)) {
                    // 不在授权页，有微信信息但没有用户信息，用openid获取一下用户
                    getUser(cookies.wechatinfo.openid)
                } else {
                    // 既有微信信息，又有用户信息，说明之前已完成过授权和绑定，利用openid重新获取一下用户信息
                    getUser(cookies.user.openid)
                }
            }
        }

        return () => {
        }
    }, [])
    
    useEffect(()=>{
        socket = io(window.server)
        connect()
        socket.on('connect', ()=> {
            console.log('connected')
        })
        socket.on('disconnect', (reason)=> {
            console.log('disconnected', reason)
            socket.connect()
        })

        // handle admin
        const adminHandler = (data) => {
            console.log(data);
            if (location.pathname !== '/bigscreen' && location.pathname !== '/admin/control') {
                if(data.command === 'unbind' && data.value === cookies.user.id) {
                    removeCookie('user')
                    removeCookie('wechatinfo')
                    if( !cookies.redirect ) setCookie('redirect', location.pathname, {secure: true, sameSite: 'none'})
                    window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appid}&redirect_uri=${window.redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                } else if (
                    data.command === 'switch_question' ||
                    data.command === 'start_question' ||
                    data.command === 'end_question' ||
                    data.command === 'start_charging' ||
                    data.command === 'clear_charging' ||
                    data.command === 'full_charging' ||
                    data.command === 'end_charging') {
                    props.onCmd(data)
                }
            }
        }
        socket.on('admin_connect', adminHandler)
        return () => {
            socket.removeAllListeners()}
    }, [])
    return (
        <>
        </>
    )
}

export default Login