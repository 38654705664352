import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import defaultAvatar from '../../../imgs/defaul_avatar.png'

function WishesSent(props) {
    const [active, setActive] = useState(props.active)
    const [cookie] = useCookies()
    const [user, setUser] = useState(cookie.user)
    const [sentList, setSentList] = useState([])
    const [receivedList, setReceivedList] = useState([])
    const [flyLeft, setFlyLeft] = useState(false)
    const [flyLeftSend, setFlyLeftSend] = useState(false)
    const [composition, setComposition] = useState("")
    const [showPopup, setShowPopup] = useState(false)
    const [showSendWish, setShowSendWish] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [wish, setWish] = useState()
    const [err, setErr] = useState('')

    const getSent = async () => {
        try {
            const {data} = await axios.get(window.server + '/api/wishes/listsent/' + user.id)
            const tempArr = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if(element.toid !== 0) {
                    const toUser = await getUser(element.toid)
                    element.toName = toUser.name
                    element.toAvatar = toUser.avatar
                    element.fromName = user.name
                    element.fromAvatar = user.avatar
                    tempArr.push(element)
                }
            }
            console.log('sent', tempArr);
            if (tempArr.length) {
                setSentList(tempArr)
                setNoResult(false)
            } else {
                setNoResult(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getReceived = async () => {
        try {
            const {data} = await axios.get(window.server + '/api/wishes/listreceived/' + user.id)
            const tempArr = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const fromUser = await getUser(element.fromid)
                element.fromName = fromUser.name
                element.fromAvatar = fromUser.avatar
                element.toName = user.name
                element.toAvatar = user.avatar
                tempArr.push(element)
            }
            if (tempArr.length) {
                setReceivedList(tempArr)
                setNoResult(false)
            } else {
                setNoResult(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async (id) => {
        try {
            const res = await axios.get(window.server + '/api/users/' + id)
            // console.log(res);
            return res.data[0]
        } catch (error) {
            console.log(error);
        }
    }
    const wishDetail = (wish) => {
        // console.log(wish);
        wish.sent = checkSent(wish)
        setWish(wish)
        setShowPopup(true)
    }
    const openSendWish = () => {
        setComposition('')
        setShowSendWish(true)
    }
    const sendWish = () => {
        console.log(composition);
        const sender = cookie.user.id
        const receiverId = wish.fromid
        console.log(sender);
        console.log(receiverId);
        if (sender && receiverId && composition) {
            axios.post(window.server + "/api/wishes/send", {
                fromid: sender,
                toid: [receiverId],
                content: composition
            }).then((res) => {
                console.log(res);
                if(res.status === 200) {
                    setShowPopup(false)
                    props.close('sent')
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setErr('内容不全')
        }
    }
    const checkSent = (wish) => {
        let result = false
        for (let index = 0; index < sentList.length; index++) {
            const element = sentList[index];
            // 如果已发送列表里的接收者toid等于当前祝福的发送者fromid
            // 说明之前已给这位发送者发过祝福了
            if (element.toid === wish.fromid) {
                result = true
                break
            }
        }
        return result
        
    }
    useEffect(() => {
        if (props.active === 'sent') {
            getSent()
        } else {
            getReceived()
        }
        return () => {
        
        }
    }, [])
    
    return (
        <div className='all-wishes'>
            <div className='nav-bar'>
                <span
                    className={active === 'sent' ? 'tab active' : 'tab'}
                    onClick={() =>{
                        setNoResult(false)
                        setActive('sent')
                        getSent()
                    }}
                >我送出的祝福</span>
                <span
                    className={active === 'received' ? 'tab active' : 'tab'}
                    onClick={() =>{
                        setNoResult(false)
                        setActive('received')
                        getReceived()
                    }}
                >我收到的祝福</span>
                <button
                    className='btn go-back'
                    onClick={() => {
                        props.close()
                    }}
                >返回</button>
            </div>
            {active === 'sent' ?
            <div className='wishes-list'>
                {sentList.length > 0 && sentList.map((wish, key) => {
                    return <div className='wish-item' key={key} onClick={() => wishDetail(wish)}>
                        <div className='wish-title'>
                            <span>TO: {wish.toName}</span>
                        </div>
                        <div className='wish-content'>
                            {wish.content}
                        </div>
                    </div>
                })}
                {!sentList.length && !noResult && <div className='loader'></div>}
                {noResult && <div className='wish-item'>无</div>}
            </div>
            :
            <div className='wishes-list'>
                {receivedList.length > 0 && receivedList.map((wish, key) => {
                    return <div className='wish-item' key={key} onClick={() => wishDetail(wish)}>
                        <div className='wish-title'>
                            <span>来自: {wish.fromName}</span>
                        </div>
                        <div className='wish-content'>
                            {wish.content}
                        </div>
                    </div>
                })}
                {!receivedList.length && !noResult && <div className='loader'></div>}
                {noResult && <div className='wish-item'>无</div>}
            </div>
            }
            {showPopup && <div className={flyLeft ? 'page compose fly-left' : 'page compose pop-up'}>
                    <div className='top-bar'>
                        <div className='targets'>
                            <span>{active === 'sent' ? 'TO: ' + wish.toName : '来自：'+ wish.fromName}</span>
                        </div>
                        <button
                            className='btn go-back'
                            onClick={() => {
                                setFlyLeft(true)
                                setTimeout(() => {
                                    setShowPopup(false)
                                    setFlyLeft(false)
                                }, 250)
                            }}
                        >返回</button>
                    </div>
                    <div className='edit-area'>
                        <div className='wish-content'>
                            {wish.content}
                        </div>
                        <img src={wish.fromAvatar ? wish.fromAvatar : defaultAvatar} className='avatar' alt='avatar' />
                        {active === 'received' && !wish.sent &&
                            <button className='send-now btn btn-yellow' onClick={openSendWish}>给TA送祝福</button>
                        }
                    </div>
                </div>
            }
            {showSendWish && <div className={flyLeftSend ? 'page compose fly-left' : 'page compose pop-up'}>
                    <div className='top-bar'>
                        <div className='targets'>
                            <span>TO: { wish.fromName }</span>
                        </div>
                        <button
                            className='btn go-back'
                            onClick={() => {
                                setErr('')
                                setFlyLeftSend(true)
                                setTimeout(() => {
                                    setShowSendWish(false)
                                    setFlyLeftSend(false)
                                }, 250)
                            }}
                        >返回</button>
                    </div>
                    <div className='edit-area'>
                        <textarea
                            value={composition}
                            maxLength='400'
                            placeholder="在此输入你的祝福语"
                            onChange={(e) => setComposition(e.target.value)}
                        />
                        {active === 'received' &&
                            <button className='send-now btn btn-yellow' onClick={sendWish}>发送祝福</button>
                        }
                        {err && <span className='form-error'>{err}</span>}
                    </div>
                </div>
            }
        </div>
    )
}

export default WishesSent