import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from "./pages/Home"
import BigScreen from "./pages/BigScreen"
import CheckIn from './pages/step1/CheckIn'
import Validate from './pages/step1/Validate'
import WishesHome from './pages/step1/WishesHome'
import ProgressControl from './pages/admin/ProgressControl'
import ChargeMT from './pages/step3/ChargeMT'
import Questions from './pages/step2/Questions'
import RedPacket from './pages/step1/components/RedPacket'

export default function Routers(props) {
  return (
    <Routes>
        <Route path="/" element={<Validate  />} />
        <Route path="/bigscreen" element={<BigScreen />} />
        <Route path='/validate' element={<Validate />} />
        <Route path="/admin">
          <Route path='control' element={<ProgressControl />} />
          <Route path='redpacket' element={<RedPacket />} />
        </Route>
        <Route path='/phase1'>
          <Route path='wishes' element={<WishesHome />} />
          <Route path='checkin' element={<CheckIn />} />
        </Route>
        <Route path='/phase2'>
          <Route path='questions' element={<Questions adminCmd = {props.adminCmd} />} />
        </Route>
        <Route path='/phase3'>
          <Route path='charge' element={<ChargeMT adminCmd = {props.adminCmd} />} />
        </Route>
    </Routes>
  )
}
